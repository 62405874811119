import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { getPromotionsSalesIsLoading } from '../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.selectors'
import { getPromotionsCodesIsLoading } from '../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes.selectors'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { PromotionsApi } from '../../../api/types/promotions-api.types'
import { promotionsPageSalesActions } from '../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.slice'
import { promotionsPageCodesActions } from '../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes.slice'
import { usePromotionsPageParams } from '../promotions-page-hooks/promotions-page.hook'
import { genPromotionsProductsDTO } from '../../../mapping/promotions.mapping'

export const usePromotionsHeader = () => {
  const dispatch = useDispatch()
  const { section } = usePromotionsPageParams()
  const isLoadingCodes = useSelector(getPromotionsSalesIsLoading)
  const isLoadingSales = useSelector(getPromotionsCodesIsLoading)

  const onCreateSales = React.useCallback(
    (salesFormValue: PromotionsApi.PromotionDTO) => {
      const products = genPromotionsProductsDTO(salesFormValue)
      dispatch(promotionsPageSalesActions.createSales({ ...salesFormValue, products: products }))
    },
    [dispatch]
  )

  const onCreateCodes = React.useCallback(
    (codesFormValue: PromotionsApi.CodeDTO) => {
      const products = genPromotionsProductsDTO(codesFormValue)
      dispatch(promotionsPageCodesActions.createCodes({ ...codesFormValue, products: products }))
    },
    [dispatch]
  )

  const onCreateCodesPattern = React.useCallback(
    (codesFormValue: PromotionsApi.CodePatternDTO) => {
      const products = genPromotionsProductsDTO(codesFormValue)
      dispatch(promotionsPageCodesActions.createCodesPattern({ ...codesFormValue, products: products }))
    },
    [dispatch]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onCreateSalesHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.PROMOTIONS_PAGE_SALES_MODAL_CREATE,
        props: {
          isLoading: isLoadingSales,
          handleOk: onCreateSales,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, isLoadingSales, onCloseHandler, onCreateSales])

  const onCreateCodesHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.PROMOTIONS_PAGE_CODES_MODAL_CREATE,
        props: {
          isLoading: isLoadingCodes,
          handleOk: onCreateCodes,
          handleOkPattern: onCreateCodesPattern,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, isLoadingCodes, onCloseHandler, onCreateCodes, onCreateCodesPattern])

  return {
    onCreateSalesHandler,
    onCreateCodesHandler,
    section,
  }
}
