import * as React from 'react'
import { FormInstance } from 'antd'

import { ExercisesGroupBookingFormValues } from './exercises-group-booking-form.types'
import {
  genExercisesGroupBookingFormPaymentTypeValidationRules,
  genExercisesGroupBookingFormClientDTO,
} from './exercises-group-booking-form.utils'
import { Nullable } from '../../../types/lang.types'
import { ClientsApi } from '../../../api/types/clients-api.types'

interface Props {
  form: FormInstance<ExercisesGroupBookingFormValues>
  onSave: (formValues: ExercisesGroupBookingFormValues, clientDTO: Nullable<ClientsApi.ClientDTO>) => void
}

export function useExercisesGroupBookingForm(props: Props) {
  const { form, onSave } = props

  const [clientCurrentData, setClientCurrentData] = React.useState<Nullable<ClientsApi.Client>>(null)

  const paymentTypeValidationRules = React.useMemo(genExercisesGroupBookingFormPaymentTypeValidationRules, [])

  const onFinishHandler = React.useCallback((): void => {
    const formValues = form.getFieldsValue()
    const clientDTO: Nullable<ClientsApi.ClientDTO> = genExercisesGroupBookingFormClientDTO(
      formValues,
      clientCurrentData
    )

    onSave(formValues, clientDTO)
  }, [clientCurrentData, form, onSave])

  return {
    paymentTypeValidationRules,
    setClientCurrentData,
    onFinishHandler,
  }
}
