import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { FetchPromotionsCodesPayload } from './promotions-page-codes.types'
import { Nullable } from '../../../../types/lang.types'
import { Pagination } from '../../../../api/types/api.types'
import { PromotionsApi } from '../../../../api/types/promotions-api.types'

export interface PromotionsPageCodesState {
  codes: Nullable<Pagination<PromotionsApi.PromotionsCodesShort>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: PromotionsPageCodesState = {
  codes: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: promotionsPageCodesActions, reducer: promotionsPageCodesReducer } = createSlice({
  name: '@@promotions-page-codes',
  initialState,
  reducers: {
    fetchAllCodes: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<Partial<FetchPromotionsCodesPayload>>) => {
      state.isLoading = true
    },
    fetchAllCodesSuccess: (
      state: Draft<PromotionsPageCodesState>,
      action: PayloadAction<Nullable<Pagination<PromotionsApi.PromotionsCodesShort>>>
    ) => {
      state.codes = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllCodesError: (state: Draft<PromotionsPageCodesState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removeCodes: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<{ id: string }>) => {
      state.isLoading = true
    },
    removeCodesSuccess: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    removeCodesError: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<Error>) => {
      state.isLoading = true
    },
    removePatternCodes: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<{ id: string }>) => {
      state.isLoading = true
    },
    removePatternCodesSuccess: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    removePatternCodesError: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<Error>) => {
      state.isLoading = true
    },
    createCodes: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<PromotionsApi.CodeDTO>) => {
      state.isLoading = true
    },
    createCodesSuccess: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    createCodesError: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<Error>) => {
      state.isLoading = true
    },
    createCodesPattern: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<PromotionsApi.CodePatternDTO>) => {
      state.isLoading = true
    },
    createCodesPatternSuccess: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    createCodesPatternError: (state: Draft<PromotionsPageCodesState>, _: PayloadAction<Error>) => {
      state.isLoading = true
    },
    reset: () => initialState,
  },
})
