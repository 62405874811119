import React from 'react'
import { Flex, Form, Input, Select, Typography } from 'antd'

import { usePromotionsFormLimitation } from './promotions-create-form-limitation.hook'
import { validateNumericInput } from '../../../../utils/form/validations-rules-form'

export const PromotionsFormLimitation = () => {
  const {
    franchisesOptions,
    counterpartiesOptions,
    clientsCategoryOptions,
    studiosOptions,
    onSelectOrganizationHandler,
    isLoadingModal,
  } = usePromotionsFormLimitation()

  return (
    <Flex vertical gap={'middle'}>
      <Typography.Title level={4} style={{ margin: '0', marginBottom: '8px' }}>
        Ограничения
      </Typography.Title>
      <Flex gap={'small'}>
        <Form.Item
          name="maxUsageCount"
          label={'Кол-во использований'}
          tooltip={'При нулевом значении кол-во использований будет неограничено'}
          initialValue={0}
          style={{ width: '100%' }}
        >
          <Input onKeyPress={validateNumericInput} size={'large'} />
        </Form.Item>
        <Form.Item
          name="maxUsageByClientCount"
          label={'Кол-во на пользователя'}
          style={{ width: '100%' }}
          initialValue={0}
          tooltip={'При нулевом значении кол-во использований будет неограничено'}
        >
          <Input onKeyPress={validateNumericInput} size={'large'} />
        </Form.Item>
      </Flex>
      <Form.Item
        name="limitedByOrganization"
        label={'Юр. лица'}
        tooltip={'При пустом поле акция действует во всех организациях'}
      >
        <Select
          size={'large'}
          mode="multiple"
          options={franchisesOptions}
          placeholder={'Любые'}
          loading={isLoadingModal}
          disabled={isLoadingModal}
          onChange={onSelectOrganizationHandler}
        />
      </Form.Item>
      <Form.Item name="limitedByStudioIds" label={'Студии'} tooltip={'При пустом поле акция действует во всех студиях'}>
        <Select
          size={'large'}
          mode="multiple"
          options={studiosOptions}
          placeholder={'Любые'}
          loading={isLoadingModal}
          disabled={isLoadingModal}
        />
      </Form.Item>
      <Form.Item
        name="limitedByClientCategoryIds"
        label={'Категории клиентов'}
        tooltip={'При пустом поле акция действует на все категории клиентов'}
      >
        <Select
          size={'large'}
          mode="multiple"
          options={clientsCategoryOptions}
          placeholder={'Любые'}
          loading={isLoadingModal}
          disabled={isLoadingModal}
        />
      </Form.Item>
      <Form.Item
        name="limitedByContractCounterpartyIds"
        label={'Контрагенты'}
        tooltip={'При пустом поле акция действует на всех контрагентов'}
      >
        <Select
          size={'large'}
          mode="multiple"
          options={counterpartiesOptions}
          placeholder={'Любые'}
          loading={isLoadingModal}
          disabled={isLoadingModal}
        />
      </Form.Item>
    </Flex>
  )
}
