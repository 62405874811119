import React from 'react'
import { Flex, FormInstance, Typography } from 'antd'

import { ClickableLabelSwitch } from '../../forms/clickableLabelSwitch/ClickableLabelSwitch.component'

interface PromotionsFormConditionsProps {
  form: FormInstance
}

export const PromotionsFormConditions = ({ form }: PromotionsFormConditionsProps) => {
  return (
    <Flex vertical>
      <Typography.Title level={4} style={{ margin: '0', marginBottom: '8px' }}>
        Условия
      </Typography.Title>
      <ClickableLabelSwitch
        name={'canMixWithOtherCampaigns'}
        label={'Можно применять вместе с промокодом'}
        form={form}
      />
      <ClickableLabelSwitch name={'newClientsOnly'} label={'Только для первой покупки'} form={form} />
      <ClickableLabelSwitch name={'afterTrialOnly'} label={'Только после пробного занятия'} form={form} />
    </Flex>
  )
}
