import { useLocation, useRouteMatch } from 'react-router-dom'

import { PromotionsPageParams, PromotionsPageQueryParams, PromotionsPageUrlParams } from '../promotions-page.types'
import { genPromotionsPageQueryParams, genPromotionsPageUrlParams } from '../promotions-page.utils'

export function usePromotionsPageParams(): PromotionsPageParams {
  const { params } = useRouteMatch<PromotionsPageUrlParams>()
  const { search } = useLocation<PromotionsPageQueryParams>()

  return { ...genPromotionsPageUrlParams(params), ...genPromotionsPageQueryParams(search) }
}
