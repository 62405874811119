import { notification } from 'antd'
import { put, takeLatest, all, race, take, call, select } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { apiV2 } from '../../../../api/api-v2'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import {
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutExercisesTotalElement,
} from './schedule-management-page-peek-about.selectors'
import { scheduleManagementPagePeekAboutActions } from './schedule-management-page-peek-about.slice'
import { genScheduleExercisesEditCapacityDTO } from '../../../../mapping/exercises-timetable.mapping'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { AppModal } from '../../../../types/modal.types'
import { ScheduleModalConflictsAction } from '../../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'

function* fetchPeekData(action: ReturnType<typeof scheduleManagementPagePeekAboutActions.fetchPeekData>) {
  try {
    const [scheduleResponse, scheduleFilter, studiosResponse, trainersResponse]: [
      Awaited<ReturnType<typeof api.exercisesTimetableService.fetchById>>,
      Awaited<ReturnType<typeof api.exercisesTimetableService.fetchExercisesFilterByTimetableId>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>,
      Awaited<ReturnType<typeof api.trainersService.fetchAll>>
    ] = yield all([
      callApi(api.exercisesTimetableService.fetchById, action.payload),
      callApi(apiV2.exercisesTimetableService.fetchExercisesFilterByTimetableId, action.payload),
      callApi(api.studiosService.fetchAll, { size: 300 }),
      callApi(api.trainersService.fetchAll, { size: 300 }),
    ])

    yield put(
      scheduleManagementPagePeekAboutActions.fetchPeekDataSuccess({
        timetable: scheduleResponse.data,
        exercisesFilterOptions: scheduleFilter.data,
        studios: studiosResponse.data,
        trainers: trainersResponse.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPagePeekAboutActions.fetchPeekDataError(new Error()))
  }
}

function* fetchTimetable(action: ReturnType<typeof scheduleManagementPagePeekAboutActions.fetchTimetable>) {
  try {
    const [scheduleResponse, scheduleFilter]: [
      Awaited<ReturnType<typeof api.exercisesTimetableService.fetchById>>,
      Awaited<ReturnType<typeof api.exercisesTimetableService.fetchExercisesFilterByTimetableId>>
    ] = yield all([
      callApi(api.exercisesTimetableService.fetchById, action.payload),
      callApi(apiV2.exercisesTimetableService.fetchExercisesFilterByTimetableId, action.payload),
    ])

    yield put(
      scheduleManagementPagePeekAboutActions.fetchTimetableSuccess({
        timetable: scheduleResponse.data,
        exercisesFilterOptions: scheduleFilter.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPagePeekAboutActions.fetchTimetableError(new Error()))
  }
}

function* fetchExercises(action: ReturnType<typeof scheduleManagementPagePeekAboutActions.fetchExercises>) {
  try {
    const { timetableId, params } = action.payload

    const filter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)

    const response: Awaited<ReturnType<typeof apiV2.exercisesTimetableService.fetchExercisesByTimetableId>> =
      yield callApi(apiV2.exercisesTimetableService.fetchExercisesByTimetableId, timetableId, {
        ...params,
        ...filter,
      })

    yield put(scheduleManagementPagePeekAboutActions.fetchExercisesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPagePeekAboutActions.fetchExercisesError(new Error()))
  }
}

export function* reFetchExercises(id: string) {
  yield put(
    scheduleManagementPagePeekAboutActions.fetchExercises({
      timetableId: id,
    })
  )

  yield race([
    take(scheduleManagementPagePeekAboutActions.fetchExercisesSuccess.type),
    take(scheduleManagementPagePeekAboutActions.fetchExercisesError.type),
  ])
}

export function* removeExercise(action: ReturnType<typeof scheduleManagementPagePeekAboutActions.removeExercise>) {
  try {
    const { scheduleId, exerciseId } = action.payload

    yield callApi(api.exercisesService.cancel, exerciseId)
    yield put(scheduleManagementPagePeekAboutActions.removeExerciseSuccess())
    yield put(modalActions.closeLast())
    yield call(reFetchExercises, scheduleId)
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPagePeekAboutActions.removeExerciseError(new Error()))
  }
}

export function* updateComment(action: ReturnType<typeof scheduleManagementPagePeekAboutActions.updateComment>) {
  try {
    const { scheduleId, comment } = action.payload

    yield callApi(apiV2.exercisesTimetableService.updateTimetableMeta, scheduleId, { comment })
    yield put(scheduleManagementPagePeekAboutActions.updateCommentSuccess())
    yield notification.success({
      message: 'Комментарий изменен',
    })
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPagePeekAboutActions.updateCommentError(new Error()))
  }
}

export function* updateCapacity(action: ReturnType<typeof scheduleManagementPagePeekAboutActions.updateCapacity>) {
  const { studioId, scheduleId, capacity } = action.payload

  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  const exercisesDTO = genScheduleExercisesEditCapacityDTO(capacity)

  yield put(websocketTimetableActions.connect(scheduleId))
  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(apiV2.exercisesTimetableService.updateExercisesByTimetableId, scheduleId, exercisesDTO)
    yield put(scheduleManagementPagePeekAboutActions.updateCapacitySuccess())

    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: ScheduleModalConflictsAction.EDIT,
          totalExercises,
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPagePeekAboutActions.updateCapacityError(new Error()))
  }
}

export function* scheduleManagementPagePeekAboutSagas() {
  yield takeLatest(scheduleManagementPagePeekAboutActions.fetchPeekData.type, fetchPeekData)
  yield takeLatest(scheduleManagementPagePeekAboutActions.fetchTimetable.type, fetchTimetable)
  yield takeLatest(scheduleManagementPagePeekAboutActions.fetchExercises.type, fetchExercises)
  yield takeLatest(scheduleManagementPagePeekAboutActions.removeExercise.type, removeExercise)
  yield takeLatest(scheduleManagementPagePeekAboutActions.updateComment.type, updateComment)
  yield takeLatest(scheduleManagementPagePeekAboutActions.updateCapacity.type, updateCapacity)
}
