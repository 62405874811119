import { createSelector } from '@reduxjs/toolkit'

import { mapClientCategoriesToOptions } from '../../../../mapping/clients-categories.mapping'
import { mapFranchisesToOptions } from '../../../../mapping/franchises.mapping'
import {
  mapCounterpartiesToOptions,
  mapProductsToOptions,
  mapSalesToPromotionsSalesTable,
  mapSelectedStudiosToOptions,
} from '../../../../mapping/promotions.mapping'
import { AppState } from '../../../app.store'

const getFranchisesInternal = (state: AppState) => state.promotionsPage.promotionsPageSales.franchises
const getCounterpartiesInternal = (state: AppState) => state.promotionsPage.promotionsPageSales.counterparties
const getStudiosInternal = (state: AppState) => state.promotionsPage.promotionsPageSales.studios
const getPromotionsCategoryInternal = (state: AppState) => state.promotionsPage.promotionsPageSales.categories
export const getSelectedStudiosInternal = (state: AppState) => state.promotionsPage.promotionsPageSales.selectedStudios
export const getPromotionsProducts = (state: AppState) => state.promotionsPage.promotionsPageSales.products
export const getPromotionsSalesInternal = (state: AppState) => state.promotionsPage.promotionsPageSales.sales

export const getPromotionsSalesIsCreating = (state: AppState) => state.promotionsPage.promotionsPageSales.isCreating
export const getPromotionsSalesIsModalLoaded = (state: AppState) =>
  state.promotionsPage.promotionsPageSales.isModalLoading
export const getStudiosList = createSelector(getStudiosInternal, studios => studios?.content)
export const getFranchisesList = createSelector(getFranchisesInternal, franchises => franchises?.content)
export const getPromotionsSalesOrganizationOptions = createSelector(getFranchisesInternal, franchises =>
  mapFranchisesToOptions(franchises?.content)
)
export const getPromotionsSalesProductsOptions = createSelector(getPromotionsProducts, mapProductsToOptions)
export const getPromotionsSalesStudiosOptions = createSelector(getSelectedStudiosInternal, studios =>
  mapSelectedStudiosToOptions(studios)
)
export const getPromotionsSalesCategoryOptions = createSelector(getPromotionsCategoryInternal, categories =>
  mapClientCategoriesToOptions(categories?.content)
)
export const getPromotionsSalesCounterpartiesOptions = createSelector(getCounterpartiesInternal, counterparties =>
  mapCounterpartiesToOptions(counterparties?.content)
)
export const getPromotionsSalesIsLoading = (state: AppState) => state.promotionsPage.promotionsPageSales.isLoading
export const getPromotionsSalesIsLoaded = (state: AppState) => state.promotionsPage.promotionsPageSales.isLoaded

export const getPromotionsSalesTotalElements = createSelector(getPromotionsSalesInternal, sales => sales?.totalElements)

export const getPromotionsSaleForTableRow = createSelector(getPromotionsSalesInternal, sales =>
  mapSalesToPromotionsSalesTable(sales?.content)
)
