import { DefaultOptionType } from 'antd/es/select'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTheme } from '../../../hooks/use-theme.hook'
import { NString, Nullable } from '../../../types/lang.types'
import { ScheduleExercisesFilter } from './schedule-exercises-filters.types'
import {
  genScheduleExercisesFiltersBookingStatusOptions,
  genScheduleExercisesFiltersPaymentStatusOptions,
  genScheduleExercisesFiltersPaymentTypeOptions,
  genScheduleExercisesFiltersTimeScopeOptions,
} from './schedule-exercises-filters.utils'

export interface UseScheduleExercisesFiltersProps {
  dateFrom?: NString
  dateTo?: NString
  exercisesFilter: ScheduleExercisesFilter
  clientsOptions?: DefaultOptionType[]
  onSelectFilter: (name: string, value?: string | string[]) => void
  onResetFilter: () => void
}

export function useScheduleExercisesFilters(props: UseScheduleExercisesFiltersProps) {
  const { dateFrom, dateTo, exercisesFilter, clientsOptions, onSelectFilter, onResetFilter } = props

  const { theme } = useTheme()
  const [dateFilter, setDateFilter] = useState<[Nullable<Dayjs>, Nullable<Dayjs>]>()

  const [isDateFilterOpen, setIsDateFilterOpen] = useState<{
    dateFrom: boolean
    dateTo: boolean
  }>({
    dateFrom: false,
    dateTo: false,
  })

  const [searchQuery, setSearchQuery] = useState('')

  const paymentStatusOptions = useMemo(genScheduleExercisesFiltersPaymentStatusOptions, [])
  const paymentTypeOptions = useMemo(genScheduleExercisesFiltersPaymentTypeOptions, [])
  const timeScopeOptions = useMemo(genScheduleExercisesFiltersTimeScopeOptions, [])
  const bookingStatusOptions = useMemo(genScheduleExercisesFiltersBookingStatusOptions, [])

  const filteredClientsOptions = clientsOptions?.filter(option => {
    const normalizePhone = (phone: string) => phone.replace(/\D/g, '')
    const containsNumbers = (str: string) => /\d/.test(str)
    const label = option.label

    if (typeof label !== 'string') return false

    const normalizedLabel = normalizePhone(label)
    const normalizedQuery = normalizePhone(searchQuery)

    if (containsNumbers(searchQuery)) {
      return normalizedLabel.includes(normalizedQuery)
    } else {
      return label.toLowerCase().includes(searchQuery.toLowerCase())
    }
  })

  const hasActiveFilters = useMemo(() => {
    return Object.values(exercisesFilter).some(
      value => value !== undefined && !(Array.isArray(value) && value.length === 0)
    )
  }, [exercisesFilter])

  const disabledDate = useCallback(
    (current: Dayjs) => {
      const startDate = dayjs(dateFrom)
      const endDate = dayjs(dateTo)

      return current.isBefore(startDate) || current.isAfter(endDate)
    },
    [dateFrom, dateTo]
  )

  const handleTrainersChange = useCallback(
    (newValue: string | string[]) => {
      const prevValue = exercisesFilter.trainerIds
      if (typeof newValue === 'object') {
        if (prevValue?.includes('noTrainer')) {
          onSelectFilter(
            'trainerIds',
            newValue.filter(value => value !== 'noTrainer')
          )
        } else if (newValue.includes('noTrainer')) {
          onSelectFilter('trainerIds', ['noTrainer'])
        } else {
          onSelectFilter('trainerIds', newValue)
        }
      }
    },
    [exercisesFilter.trainerIds, onSelectFilter]
  )

  const handleClientsChange = useCallback(
    (newValue: string | string[]) => {
      const prevValue = exercisesFilter.clients
      if (typeof newValue === 'object') {
        if (prevValue?.includes('all')) {
          onSelectFilter(
            'clients',
            newValue.filter(value => value !== 'all')
          )
        } else if (newValue.includes('all')) {
          onSelectFilter('clients', ['all'])
        } else {
          onSelectFilter('clients', newValue)
        }
      }
    },
    [exercisesFilter.clients, onSelectFilter]
  )

  const handleStudioChange = useCallback(
    (value: string | string[]) => {
      onSelectFilter('studioId', value)
      onSelectFilter('roomId', undefined)
    },
    [onSelectFilter]
  )

  const handleDatesChange = useCallback(
    (type: 'from' | 'to', date?: Nullable<Dayjs>) => {
      setDateFilter(prevDateFilter => [
        type === 'from' ? date : prevDateFilter?.[0],
        type === 'to' ? date : prevDateFilter?.[1],
      ])

      onSelectFilter(`date${type === 'from' ? 'From' : 'To'}`, date ? date.format('YYYY-MM-DD') : undefined)

      if (!date) {
        setIsDateFilterOpen(prevState => ({
          ...prevState,
          [`date${type === 'from' ? 'From' : 'To'}`]: false,
        }))
      }
    },
    [onSelectFilter]
  )

  const handleResetFilter = useCallback(() => {
    setDateFilter([null, null])
    setIsDateFilterOpen({
      dateFrom: false,
      dateTo: false,
    })
    onResetFilter()
  }, [onResetFilter])

  const toggleDateFilter = useCallback((filterType: 'from' | 'to') => {
    setIsDateFilterOpen(prevState => ({
      ...prevState,
      [`date${filterType === 'from' ? 'From' : 'To'}`]: !prevState[`date${filterType === 'from' ? 'From' : 'To'}`],
    }))
  }, [])

  const handleFilterVisibleChange = (visible: boolean) => {
    if (visible) {
      document.body.classList.add('app-no-scroll')
    } else {
      document.body.classList.remove('app-no-scroll')
    }
  }

  useEffect(() => {
    if (!isDateFilterOpen.dateFrom) {
      handleDatesChange('from', null)
    }

    if (!isDateFilterOpen.dateTo) {
      handleDatesChange('to', null)
    }
  }, [isDateFilterOpen.dateFrom, isDateFilterOpen.dateTo, handleDatesChange])

  return {
    theme,
    dateFilter,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
    isDateFilterOpen,
    hasActiveFilters,
    filteredClientsOptions,
    searchQuery,
    setSearchQuery,
    disabledDate,
    handleTrainersChange,
    handleClientsChange,
    handleStudioChange,
    handleDatesChange,
    handleResetFilter,
    toggleDateFilter,
    handleFilterVisibleChange,
  }
}
