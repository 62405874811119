import React from 'react'
import { useHistory } from 'react-router-dom'

import { usePromotionsPageParams } from '../promotions-page-hooks/promotions-page.hook'
import { validateStrEnumValue } from '../../../utils/enum.utils'
import { PromotionsPageSection } from '../promotions-page.types'
import { isDef } from '../../../types/lang.types'
import { genPromotionsPagePath } from '../../../format/path.format'

export const usePromotionsTabs = () => {
  const { push } = useHistory()
  const { section } = usePromotionsPageParams()

  const onChangeHandler = React.useCallback(
    (value: string) => {
      const section = validateStrEnumValue<PromotionsPageSection>(PromotionsPageSection, value)

      if (isDef(section)) {
        push(genPromotionsPagePath({ section }))
      }
    },
    [push]
  )

  return {
    section,
    onChangeHandler,
  }
}
