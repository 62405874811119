import { Card, Button, Tag, Typography, Flex, Row, Col, Dropdown, MenuProps } from 'antd'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useState, useMemo } from 'react'

import { formatPhoneNumber } from '../../../../format/phone.format'
import { formatDays, formatPluralized } from '../../../../format/text.format'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { ScheduleListCardProps } from './schedule-list-card.types'
import { TableCellActions } from '../../../table-cells/table-cell-actions/table-cell-actions.component'
import { useTheme } from '../../../../hooks/use-theme.hook'
import './schedule-list-card.styles.less'
import { useScheduleListCard } from './schedule-list-card.hook'

const { Text } = Typography

export const ScheduleListCard: React.FC<ScheduleListCardProps> = props => {
  const { scheduleItem, timetableDeletePermission } = props
  const { onCreateTransaction, onSelectPaymentType, onEdit, onRemove, onHistory } = props

  const { theme } = useTheme()
  const { paymentTypes, isLoading, onPayHandler } = useScheduleListCard({
    scheduleItem,
    onCreateTransaction,
  })

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

  const onHistoryHandler = () => onHistory(scheduleItem.id)
  const onEditHandler = () => onEdit(scheduleItem.id)
  const onRemoveHandler = () => onRemove(scheduleItem.id)

  const paymentTypesItems: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '1',
        label: (
          <div
            onClick={() => {
              setDropdownMenuOpen(false)
              onCreateTransaction(scheduleItem.client.phone, scheduleItem.client.id)
            }}
          >
            Создать транзакцию
          </div>
        ),
      },
      ...(isDefAndNotEmpty(paymentTypes)
        ? [
            {
              key: '2',
              type: 'group',
              label: 'В наличии у клиента',
              children: paymentTypes.map((option, index) => ({
                key: `1-${index}`,
                label: (
                  <div
                    onClick={() => {
                      setDropdownMenuOpen(false)
                      onEdit(scheduleItem.id)
                      onSelectPaymentType(scheduleItem.id, scheduleItem.client.phone, option.value?.toString())
                    }}
                  >
                    {option.label}
                  </div>
                ),
              })),
            },
          ]
        : []),
    ]
  }, [paymentTypes, scheduleItem, onCreateTransaction, onEdit, onSelectPaymentType])

  return (
    <Card
      className={clsx('schedule-list-card', {
        'schedule-list-card--dark': theme === 'dark',
      })}
    >
      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col style={{ flex: '1 1 240px' }}>
          <Flex vertical align="start" gap="4px">
            <Text style={{ fontSize: '12px' }} type="secondary">
              {formatPluralized(scheduleItem.totalExercises, ['запись', 'записи', 'записей'])}
            </Text>
            <Text>{scheduleItem.direction.title}</Text>
            {scheduleItem.format === 'PERSONAL' && <Tag color="cyan">Персональная услуга</Tag>}
          </Flex>
        </Col>

        <Col flex="150px">
          <Flex vertical align="baseline">
            <Typography.Text>{scheduleItem.client.name}</Typography.Text>
            <Link to={`/clients/${scheduleItem.client.id}`}>{formatPhoneNumber(`+${scheduleItem.client.phone}`)}</Link>
            <Typography.Text type="secondary">{scheduleItem.client.category}</Typography.Text>
          </Flex>
        </Col>

        <Col flex="auto">
          <>
            {scheduleItem.payedBookingsCount !== scheduleItem.totalExercises ? (
              <Flex vertical align="baseline" gap={10}>
                {scheduleItem.payedBookingsCount > 0 ? (
                  <Flex vertical align="baseline">
                    <Typography.Text type="secondary">Частичная оплата</Typography.Text>
                    <Typography.Text>
                      Оплачено {scheduleItem.payedBookingsCount} из {scheduleItem.totalExercises}
                    </Typography.Text>
                  </Flex>
                ) : (
                  <Tag color="orange">Не оплачено</Tag>
                )}
                <Dropdown
                  open={isLoading ? false : dropdownMenuOpen}
                  onOpenChange={(nextOpen, info) => {
                    if (info.source === 'trigger' || nextOpen) {
                      setDropdownMenuOpen(nextOpen)
                    }
                  }}
                  menu={{ items: paymentTypesItems }}
                  placement="bottom"
                  arrow
                  trigger={['click']}
                >
                  <Button
                    size="small"
                    type="dashed"
                    className="schedule-list-card__button"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={onPayHandler}
                  >
                    Создать оплату
                  </Button>
                </Dropdown>
              </Flex>
            ) : (
              <Tag color="green">Оплачено</Tag>
            )}
          </>
        </Col>

        <Col style={{ flex: '1 1 10%' }}>
          {isDefAndNotEmpty(scheduleItem.trainers) ? (
            <div>{scheduleItem.trainers.map(it => it.title).join(', ')}</div>
          ) : (
            'Без исполнителя'
          )}
        </Col>
        <Col flex="auto">
          <Flex vertical align="start" gap="4px">
            <Tag color={scheduleItem.studioRooms[0].color}>{scheduleItem.studioRooms[0].name}</Tag>
            {scheduleItem.studioRooms.length > 1 && <Tag>+ {scheduleItem.studioRooms.length - 1}</Tag>}
          </Flex>
        </Col>
        <Col flex="auto">
          <Flex vertical style={{ fontSize: '12px', color: '#000000A6' }}>
            <Text style={{ fontSize: '10px' }} type="secondary">
              {scheduleItem.daysOfWeek.map(day => formatDays(day)).join(', ')}
            </Text>
            <Text>С {dayjs(scheduleItem.dateFrom).format('DD MMMM YYYY')}</Text>
            <Text>по {dayjs(scheduleItem.dateTo).format('DD MMMM YYYY')}</Text>
            <Text style={{ fontSize: '10px' }} type="secondary">
              Осталось {formatPluralized(scheduleItem.futureExercises, ['запись', 'записи', 'записей'])}
            </Text>
          </Flex>
        </Col>
        <Col style={{ width: '144px' }}>
          <TableCellActions
            space="small"
            forbidRemove={!timetableDeletePermission}
            onEdit={onEditHandler}
            onRemove={onRemoveHandler}
            onHistory={onHistoryHandler}
          />
        </Col>
      </Row>
    </Card>
  )
}
