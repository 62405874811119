import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'

import { genPromotionsPagePath } from '../../../../format/path.format'
import { PromotionsPageSection } from '../../promotions-page.types'
import { genPaginationConfig } from '../../../../utils/pagination.utils'
import {
  getPromotionsSaleForTableRow,
  getPromotionsSalesIsLoading,
  getPromotionsSalesTotalElements,
} from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.selectors'
import { promotionsPageSalesActions } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.slice'
import { usePromotionsPageParams } from '../../promotions-page-hooks/promotions-page.hook'

export const useSalesTabs = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const { page, size } = usePromotionsPageParams()

  const isLoading = useSelector(getPromotionsSalesIsLoading)
  const sales = useSelector(getPromotionsSaleForTableRow)
  const totalElements = useSelector(getPromotionsSalesTotalElements)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const onEditHandler = React.useCallback((id: string): void => {
    notification.error({ message: 'Пока не работает' })
  }, [])

  const onRemoveHandler = React.useCallback(
    (id: string): void => {
      dispatch(promotionsPageSalesActions.removeSales({ id }))
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.SALES }, { page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.SALES }, { page, size: pageSize }))
    },
    [page, push]
  )

  const onVisiblePastSalesHandler = React.useCallback(
    (value: boolean) => {
      dispatch(promotionsPageSalesActions.fetchAllSales({ page, size, isShowPast: !value }))
    },
    [dispatch, page, size]
  )

  React.useEffect(() => {
    dispatch(promotionsPageSalesActions.fetchAllSales({ page, size }))
  }, [dispatch, page, size])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageSalesActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    sales,
    pagination,
    onChangePageHandler,
    onChangePageSizeHandler,
    onEditHandler,
    onRemoveHandler,
    onVisiblePastSalesHandler,
  }
}
