import { ChangeEvent, useEffect, useState } from 'react'

import { ScheduleOverview } from './schedule-overview.types'

export interface UseScheduleOverviewProps {
  schedule: ScheduleOverview
  onEditComment: (comment: string) => void
  onEditCapacity: (capacity: number) => void
  onClient: (clientId: string) => void
}

export function useScheduleOverview(props: UseScheduleOverviewProps) {
  const { schedule, onClient, onEditComment, onEditCapacity } = props

  const [capacity, setCapacity] = useState<number>()
  const [comment, setComment] = useState<string>()
  const [isEditing, setIsEditing] = useState(false)

  const handleEditCommentClick = () => {
    setIsEditing(true)
  }

  const handleInputCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value)
  }

  const handleAddCommentClick = () => {
    setIsEditing(true)
  }

  const handleSaveCommentClick = () => {
    setIsEditing(false)
    onEditComment(comment || '')
  }

  const handleClientClick = () => {
    if (schedule.client?.id) {
      onClient(schedule.client.id)
    }
  }

  const handleCapacityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCapacity(Number(e.target.value))
  }

  const handleSaveCapacityClick = () => {
    if (capacity) {
      onEditCapacity(capacity)
    }
  }

  useEffect(() => {
    if (schedule.comment) {
      setComment(schedule.comment)
    }
  }, [schedule.comment])

  return {
    comment,
    isEditing,
    capacity,
    handleEditCommentClick,
    handleInputCommentChange,
    handleAddCommentClick,
    handleSaveCommentClick,
    handleClientClick,
    handleCapacityChange,
    handleSaveCapacityClick,
  }
}
