import { Button, Flex, Form, FormInstance, Input } from 'antd'

import { formatPluralized } from '../../../../format/text.format'
import { ScheduleEditExercisesFormValues } from '../schedule-modal-edit.types'

interface Props {
  isLoading: boolean
  form: FormInstance<ScheduleEditExercisesFormValues>
  totalExercises?: number
  onEditExercisesComment: () => void
}
export const ScheduleModalEditComment: React.FC<Props> = props => {
  const { isLoading, form, totalExercises, onEditExercisesComment } = props

  const onEditCommentHandler = async () => {
    try {
      await form.validateFields(['comment'])
      onEditExercisesComment()
    } catch (errorInfo) {}
  }

  return (
    <Flex vertical gap={8} align="baseline">
      <Form.Item
        className="schedule-modal-edit__form-item"
        label="Комментарий"
        name="comment"
        rules={[{ required: true, message: 'Введите комментарий' }]}
      >
        <Input.TextArea placeholder="Введите комментарий" />
      </Form.Item>

      <Button htmlType="button" type="primary" loading={isLoading} onClick={onEditCommentHandler}>
        Применить изменения для {formatPluralized(totalExercises || 0, ['записи', 'записей', 'записей'])}
      </Button>
    </Flex>
  )
}
