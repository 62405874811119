import React from 'react'
import { Button, Flex, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { PromotionsPageSection } from '../promotions-page.types'
import { usePromotionsHeader } from './promotions-page-header.hook'

export const PromotionsHeader = () => {
  const { onCreateSalesHandler, onCreateCodesHandler, section } = usePromotionsHeader()

  return (
    <Flex justify={'space-between'} align={'center'}>
      <Typography.Title level={2} style={{ margin: 0 }}>
        Промокоды и акции
      </Typography.Title>
      <Button
        icon={<PlusOutlined color={'white'} />}
        type={'primary'}
        onClick={section === PromotionsPageSection.SALES ? onCreateSalesHandler : onCreateCodesHandler}
      >
        Создать {section === PromotionsPageSection.SALES ? 'акцию' : 'промокод'}
      </Button>
    </Flex>
  )
}
