import { FranchisesForm } from '../../../components/franchises/franchises-form/franchises-form.component'
import { useFranchisesEditPageForm } from './franchises-edit-page-form.hook'

export const FranchisesEditPageForm = () => {
  const {
    form,
    isLoading,
    isUpdating,
    studiosOptions,
    customersOptions,
    terminals,
    tills,
    onEditFranchiseHandler,
    onRemoveFranchiseHandler,
  } = useFranchisesEditPageForm()

  return (
    <FranchisesForm
      form={form}
      isLoading={isLoading}
      isUpdating={isUpdating}
      studiosOptions={studiosOptions}
      tills={tills}
      terminals={terminals}
      customersOptions={customersOptions}
      isEdit
      onFinish={onEditFranchiseHandler}
      onRemoveFranchise={onRemoveFranchiseHandler}
    />
  )
}
