import { FC, useMemo, useState } from 'react'
import { Typography, Flex, Card, Dropdown, Button, Menu, Spin, Checkbox, CheckboxProps } from 'antd'
import { ClientsBookingsApi } from '@api/types/clients-bookings-api.types'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'

import './transactions-create-page-unpaid-records-select.styles.less'
import { ITransactionsCreatePageUnpaidRecordsSelectProps } from './transactions-create-page-unpaid-records-select.types'
import { UnpaidRecordOption } from './ui/unpaid-record-option/unpaid-record-option.component'
import { useStudio } from '../../../hooks/use-studios.hook'
import { filterPastRecords } from './transactions-create-page-unpaid-records-select.utils'

export const TransactionsCreatePageUnpaidRecordsSelect: FC<ITransactionsCreatePageUnpaidRecordsSelectProps> = ({
  records,
  isLoadingRecords,
  addUnpaidRecord,
  selectedUnpaidRecords,
}) => {
  const { studioOffset } = useStudio()

  // SECTION: State
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [includeCompletedRecords, setIncludeCompletedRecords] = useState(false)

  // SECTION: Computed
  const filteredRecords = useMemo(
    () => (!includeCompletedRecords ? filterPastRecords(records, studioOffset || 0) : records),
    [includeCompletedRecords, records, studioOffset]
  )

  const unselectedRecords = useMemo<ClientsBookingsApi.UnpaidRecord[]>(() => {
    if (!filteredRecords.length) return []

    return (filteredRecords ?? []).filter(
      record =>
        !selectedUnpaidRecords.find(
          selectedRecord => JSON.stringify(selectedRecord.bookingIds) === JSON.stringify(record.bookingIds)
        )
    )
  }, [filteredRecords, selectedUnpaidRecords])

  // SECTION: Actions
  const handleAddUnpaidRecord = (id: string) => {
    const selectedRecord = records.find(record => record.responseId === id)
    if (selectedRecord) {
      addUnpaidRecord(selectedRecord)
      setDropdownVisible(false)
    }
  }

  const handleDropdownVisibleChange = (flag: boolean) => {
    if (flag) return
    setDropdownVisible(flag)
  }

  const handleIncludeCompletedRecords: CheckboxProps['onChange'] = e => {
    setIncludeCompletedRecords(e.target.checked)
  }

  if (!records.length) return null
  return (
    <Dropdown
      overlay={
        <Menu className="transactions-create-page-unpaid-records-menu">
          <Menu.Item
            className="transactions-create-page-unpaid-records-menu__checkbox"
            key="checkbox"
            disabled={isLoadingRecords}
          >
            <Checkbox checked={includeCompletedRecords} onChange={handleIncludeCompletedRecords}>
              С учетом прошедших записей
            </Checkbox>
          </Menu.Item>
          {unselectedRecords.map(record => (
            <Menu.Item className="transactions-create-page-unpaid-records-menu__item" key={record.responseId}>
              <UnpaidRecordOption record={record} handleAddUnpaidRecord={handleAddUnpaidRecord} />
            </Menu.Item>
          ))}
        </Menu>
      }
      onVisibleChange={handleDropdownVisibleChange}
      visible={dropdownVisible}
      trigger={['click']}
      placement="bottom"
    >
      <Card
        className="transactions-create-page-unpaid-records-select"
        data-component-name="TransactionsCreatePageUnpaidRecordsSelect"
      >
        <Flex gap="middle" align="center" justify="space-between">
          <Typography.Text style={{ whiteSpace: 'nowrap' }}>Неоплаченные записи</Typography.Text>

          {isLoadingRecords ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: '#bfbfbf' }} spin />} />
          ) : (
            <Button
              className="transactions-create-page-unpaid-records-select__pseudo-select"
              onClick={() => setDropdownVisible(true)}
            >
              <Flex justify="space-between">
                <span>Выбрать и добавить в транзакцию</span>
                <DownOutlined style={{ color: '#00000040' }} />
              </Flex>
            </Button>
          )}
        </Flex>
      </Card>
    </Dropdown>
  )
}
