import { FC, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { DeleteOutlined, LoadingOutlined, RetweetOutlined } from '@ant-design/icons'
import { Typography, Flex, Card, Button, InputNumber, Dropdown, Tag } from 'antd'
import { api } from '@api/api'
import { TableCellTagProductType } from '@components/table-cells/table-cell-tag-product-type/table-cell-tag-product-type.component'
import { ProductsApi } from '@api/types/products-api.types'
import { formatRublesPenniesCurrency } from '@format/number.format'
import { useDispatch } from 'react-redux'
import { transactionsCreatePageModalProductsActions } from '@store/pages/transactions-create-page/transactions-create-page-modal-products/transactions-create-page-modal-products.slice'
import { transactionsCreatePageTableActions } from '@store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.slice'
import { ClientsBookingsApi } from '@api/types/clients-bookings-api.types'

import { MarkCodesAndCountableEntityItem } from '../../../../../types/lang.types'
import { IVisitCardProps } from './visit-card.types'
import './visit-card.styles.less'

export const VisitCard: FC<IVisitCardProps> = ({
  record,
  removeUnpaidRecord,
  defaultValues,
  hasRedirectDefaultValues,
  setHasRedirectDefaultValues,
  booking,
  clientId,
}) => {
  // SECTION: State
  const [currentProduct, setCurrentProduct] = useState<ProductsApi.Product | null>(null)
  const [currentFullProduct, setFullProduct] = useState<MarkCodesAndCountableEntityItem<ProductsApi.Product> | null>(
    null
  )
  const [availableProducts, setAvailableProducts] = useState<ProductsApi.Product[]>([])
  const [isLongRecord] = useState(hasRedirectDefaultValues ? false : record.bookingIds.length > 1)
  const [isLoading, setIsLoading] = useState(false)

  // SECTION: Hooks
  const dispatch = useDispatch()

  // SECTION: Life cycle
  // Set product by default
  useEffect(() => {
    setIsLoading(true)
    const getActiveRecordsForProduct = async () => {
      const products = await api.productService.fetchAvailable(record.exerciseIds, clientId)

      setAvailableProducts(products?.data ?? [])
      setCurrentProduct(products?.data?.[0] ?? null)

      if (products?.data?.[0]) {
        const newProduct = {
          ...products?.data?.[0],
          onlyUnpaidRecord: true,
        }

        newProduct.bookingIds =
          hasRedirectDefaultValues && defaultValues?.bookingId ? [defaultValues.bookingId] : record.bookingIds

        dispatch(
          transactionsCreatePageModalProductsActions.handleProduct({
            product: newProduct,
            successCallback: product => {
              setFullProduct(product)
              setIsLoading(false)
            },
          })
        )
      }
    }

    getActiveRecordsForProduct()

    if (hasRedirectDefaultValues) {
      setHasRedirectDefaultValues(false)
    }
  }, [])

  // SECTION: Actions
  const updateDiscount = (discount: number | null) => {
    dispatch(
      transactionsCreatePageTableActions.setDiscount({
        productUuid: currentFullProduct?.uuid as unknown as string,
        discount: discount ? discount * 100 : 0,
      })
    )
  }

  const handleClickByItem = (product: ProductsApi.Product) => {
    const oldProductUUID = currentFullProduct?.uuid
    setCurrentProduct(product)

    const newProduct = { ...product, onlyUnpaidRecord: true }
    newProduct.bookingIds = record.bookingIds

    dispatch(
      transactionsCreatePageModalProductsActions.handleProduct({
        product: newProduct,
        successCallback: product => {
          setFullProduct(product)
          oldProductUUID && dispatch(transactionsCreatePageTableActions.removeProduct(oldProductUUID))
        },
      })
    )
  }

  const handleRemoveProduct = () => {
    removeUnpaidRecord(record.responseId)
    currentFullProduct && dispatch(transactionsCreatePageTableActions.removeProduct(currentFullProduct.uuid))
  }

  const longRecordTitle = useMemo(() => {
    if (!isLongRecord) return ''
    if (!record?.timetable) return ''

    const formatDay = (date: string) => dayjs(date).format('D MMMM')
    const formatTime = (time: string) => dayjs(time, 'HH:mm:ss').format('HH:mm')
    const formatWeekdays = (timeslots: ClientsBookingsApi.UnpaidRecordTimeslots) => {
      const weekdays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
      const weekdaysInEnglish = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
      const activeDays = Object.keys(timeslots)
      return weekdays.filter((_, index) => activeDays.includes(weekdaysInEnglish[index])).join(', ')
    }

    const startTime = Object.values(record.timetable.timeslots).flat()[0]?.timeFrom

    return `с ${formatDay(record.timetable.dateFrom)} по ${formatDay(record.timetable.dateTo)}, ${formatWeekdays(
      record.timetable.timeslots
    )}, ${startTime ? formatTime(startTime) : ''}, ${record.exerciseType.name}, ${record.bookingIds.length} записей`
  }, [record, isLongRecord])

  const duration = useMemo(() => {
    if (!record?.exerciseDuration) return ''
    const duration = dayjs.duration(record?.exerciseDuration)
    const hours = duration.hours()
    const minutes = duration.minutes()

    let formattedDuration = `${hours} час${hours !== 1 ? 'а' : ''}`
    if (hours === 0) formattedDuration = ''
    if (minutes > 0) formattedDuration += ` ${minutes} минут`
    return `, ${formattedDuration}`
  }, [record])

  const simpleRecordTitle = useMemo(() => {
    if (hasRedirectDefaultValues && booking) {
      return `${dayjs(booking.exerciseDate, "'YYYY-MM-DDTHH:mm'").format('D MMMM, в HH:mm')}${duration}, ${
        record.exerciseType.name
      }`
    } else {
      return `${dayjs(record.exerciseDate, "'YYYY-MM-DDTHH:mm'").format('D MMMM, в HH:mm')}${duration}, ${
        record.exerciseType.name
      }`
    }
  }, [record, duration])

  return (
    <Card
      className="visit-card"
      data-response-id={record?.responseId ?? 'undefined'}
      data-product-id={currentProduct?.id ?? 'undefined'}
      data-component-name="VisitCard"
    >
      <Flex vertical gap="small">
        <div className="visit-card__title" data-date={record.exerciseDate}>
          {isLongRecord ? longRecordTitle : simpleRecordTitle}
        </div>

        <div className="visit-card__content">
          <Flex gap="small" align="flex-start">
            {availableProducts.length > 1 && (
              <Dropdown
                menu={{
                  items: availableProducts
                    .filter(product => product.id !== currentProduct?.id)
                    .map(product => ({
                      key: product.id,
                      onClick: () => handleClickByItem(product),
                      label: (
                        <Flex vertical gap="small" className="visit-card__dropdown-option">
                          <Flex gap="middle">
                            <TableCellTagProductType productType={product.productType} />
                            <Typography.Text className="visit-card__dropdown-option-price">
                              {formatRublesPenniesCurrency(product.cost)}
                            </Typography.Text>
                          </Flex>

                          <Typography.Title level={5} className="visit-card__dropdown-option-title">
                            {product?.name}
                          </Typography.Title>
                        </Flex>
                      ),
                    })),
                }}
                placement="topLeft"
                arrow
              >
                <Button size="small" icon={<RetweetOutlined />} />
              </Dropdown>
            )}

            {!isLoading && (
              <Flex vertical justify="flex-end" gap="4px">
                <Typography.Text>{currentProduct?.name ?? 'Услуг не найдено'}</Typography.Text>
                <Flex>
                  {currentProduct && <TableCellTagProductType productType={currentProduct.productType} />}
                  {isLongRecord && <Tag color="blue">Длинная запись</Tag>}
                </Flex>
                {/* <Typography.Text className="visit-card__title__content-text">
                  Название персональной услуги
                </Typography.Text> */}
              </Flex>
            )}
          </Flex>

          {isLoading ? (
            <Flex
              style={{
                flexGrow: 1,
                textAlign: 'center',
              }}
              vertical
              justify="center"
            >
              <div>
                <LoadingOutlined />
              </div>
            </Flex>
          ) : (
            <>
              {currentProduct && (
                <Typography.Title level={5}>{formatRublesPenniesCurrency(currentProduct!.cost)}</Typography.Title>
              )}
              {currentProduct && (
                <Flex vertical gap="7px" style={{ marginTop: '-22px' }}>
                  <p style={{ color: '#515151', fontSize: '12px', margin: '0', marginLeft: '11px' }}>Скидка</p>
                  <InputNumber style={{ width: '100px' }} addonAfter="₽" onChange={updateDiscount} />
                </Flex>
              )}
              <Button onClick={handleRemoveProduct} danger icon={<DeleteOutlined />} />
            </>
          )}
        </div>
      </Flex>
    </Card>
  )
}
