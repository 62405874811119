import React from 'react'
import { Button, Flex, Form, Input, Modal } from 'antd'
import style from '@pages/contracts-page/contracts-page-modal-create/contracts-modal-create.module.scss'
import { clsx } from 'clsx'

import { validateNumericInput } from '../../../../utils/form/validations-rules-form'
import { useTheme } from '../../../../hooks/use-theme.hook'
import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { AppModalBaseProps } from '../../../../types/modal.types'

export interface ContractsPaidModalProps extends AppModalBaseProps {
  handleOk: (values: ContractsApi.ContractPaidParams) => void
  handleCancel: () => void
  contractSum?: number
}

export const ContractsPaidModal = ({ handleOk, handleCancel, contractSum }: ContractsPaidModalProps) => {
  const { theme } = useTheme()

  return (
    <Modal
      title="Зачисление баланса"
      open={true}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" htmlType="submit">
          Применить оплату
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          autoComplete="off"
          layout="vertical"
          name="contract_paid_form"
          initialValues={{
            modifier: 'public',
          }}
          onFinish={handleOk}
        >
          {dom}
        </Form>
      )}
    >
      <Flex
        vertical
        className={clsx({ [style.formContainerLight]: theme === 'light', [style.formContainerDark]: theme === 'dark' })}
        gap={'small'}
      >
        <Form.Item
          name="paymentAmount"
          label="Сумма зачисления"
          initialValue={contractSum}
          rules={[
            {
              required: true,
              message: 'Введите сумму зачисления!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} suffix="₽" />
        </Form.Item>
      </Flex>
    </Modal>
  )
}
