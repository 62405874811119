import { AxiosResponse } from 'axios'

import { PromotionsApi } from '../types/promotions-api.types'
import { HttpConnector } from '../connectors/http.connector'
import { Nullable } from '../../types/lang.types'
import { Pagination, PaginationParamsDTO } from '../types/api.types'
import { ProductsApi } from '../types/products-api.types'
import { SearchPromotionsProductParams } from '../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.types'

export class PromotionsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAllPromotionsSales = (
    params: Nullable<Partial<PaginationParamsDTO>>
  ): Promise<AxiosResponse<Pagination<PromotionsApi.PromotionsSalesShort>>> => {
    return this.httpConnector.get<Pagination<PromotionsApi.PromotionsSalesShort>>('/promotions', {
      params,
    })
  }

  public fetchAllPromotionsCodes = (
    params: Nullable<Partial<PaginationParamsDTO>>
  ): Promise<AxiosResponse<Pagination<PromotionsApi.PromotionsCodesShort>>> => {
    return this.httpConnector.get<Pagination<PromotionsApi.PromotionsCodesShort>>('/promotions/codes', { params })
  }

  public createPromotionsSales = (data: PromotionsApi.PromotionDTO): Promise<void> => {
    return this.httpConnector.post('/promotions', data)
  }

  public createPromotionsCodes = (data: PromotionsApi.CodeDTO): Promise<void> => {
    return this.httpConnector.post('/promotions/codes', data)
  }

  public createPromotionsCodesPattern = (data: PromotionsApi.CodePatternDTO): Promise<void> => {
    return this.httpConnector.post('/promotions/codes/pattern', data)
  }

  public generatePromotionsCodesPattern = (count: number, id: string): Promise<{ id: string; name: string }[]> => {
    return this.httpConnector.post(`/promotions/codes/pattern/${id}/generate`, count)
  }

  public removePromotionsSales = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/promotions/${id}`)
  }

  public removePromotionsCodes = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/promotions/codes/${id}`)
  }

  public removePromotionsPatternCodes = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/promotions/codes/pattern/${id}`)
  }

  public searchPromotionsProduct = (
    params: Nullable<Partial<SearchPromotionsProductParams>>
  ): Promise<AxiosResponse<ProductsApi.Product[]>> => {
    return this.httpConnector.get('/promotions/products', { params })
  }
}
