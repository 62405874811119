import * as React from 'react'
import { Button, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { DeleteOutlined } from '@ant-design/icons'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModalBaseProps } from '../../../types/modal.types'

export interface ModalConfirmProps extends AppModalBaseProps {
  title: string
  text: string
  onSubmit: () => void
}

export const ModalConfirm: React.FC<ModalConfirmProps> = props => {
  const { title, text, onSubmit } = props

  const dispatch = useDispatch()

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onSubmitHandler = React.useCallback((): void => {
    onSubmit()
    onCancelHandler()
  }, [onCancelHandler, onSubmit])

  return (
    <Modal
      title={title}
      open={true}
      onCancel={onCancelHandler}
      footer={[
        <Button key="back" onClick={onCancelHandler}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" danger onClick={onSubmitHandler} icon={<DeleteOutlined />}>
          Подтвердить
        </Button>,
      ]}
    >
      <p>{text}</p>
    </Modal>
  )
}
