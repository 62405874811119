import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { OrganizationsApi } from '../../../../api/types/organizations-api.types'
import { ClientsCategoriesApi } from '../../../../api/types/clients-categories-api.types'
import { ProductsApi } from '../../../../api/types/products-api.types'
import { StudiosApi } from '../../../../api/types/studios-api.types'
import {
  FetchPromotionsModalData,
  FetchPromotionsSalesPayload,
  SearchPromotionsProductParams,
} from './promotions-page-sales.types'
import { Nullable } from '../../../../types/lang.types'
import { Pagination } from '../../../../api/types/api.types'
import { PromotionsApi } from '../../../../api/types/promotions-api.types'

export interface PromotionsPageSalesState {
  sales: Nullable<Pagination<PromotionsApi.PromotionsSalesShort>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  products: Nullable<ProductsApi.Product[]>
  selectedStudios: { id: string; name: string }[]
  counterparties: Nullable<Pagination<ContractsApi.Counterparties>>
  franchises: Nullable<Pagination<OrganizationsApi.Organization>>
  categories: Nullable<Pagination<ClientsCategoriesApi.ClientCategory>>
  isLoading: boolean
  isLoaded: boolean
  isModalLoading: boolean
  isModalLoaded: boolean
  isCreating: boolean
  isCreated: boolean
  error: Nullable<Error>
}

const initialState: PromotionsPageSalesState = {
  sales: null,
  counterparties: null,
  franchises: null,
  categories: null,
  studios: null,
  products: null,
  selectedStudios: [],
  isLoading: false,
  isLoaded: false,
  isModalLoading: false,
  isModalLoaded: false,
  isCreating: false,
  isCreated: false,
  error: null,
}

export const { actions: promotionsPageSalesActions, reducer: promotionsPageSalesReducer } = createSlice({
  name: '@@promotions-page-sales',
  initialState,
  reducers: {
    fetchAllSales: (state: Draft<PromotionsPageSalesState>, _: PayloadAction<Partial<FetchPromotionsSalesPayload>>) => {
      state.isLoading = true
    },
    fetchAllSalesSuccess: (
      state: Draft<PromotionsPageSalesState>,
      action: PayloadAction<Nullable<Pagination<PromotionsApi.PromotionsSalesShort>>>
    ) => {
      state.sales = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllSalesError: (state: Draft<PromotionsPageSalesState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removeSales: (state: Draft<PromotionsPageSalesState>, _: PayloadAction<{ id: string }>) => {
      state.isLoading = true
    },
    removeSalesSuccess: (state: Draft<PromotionsPageSalesState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    removeSalesError: (state: Draft<PromotionsPageSalesState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    createSales: (state: Draft<PromotionsPageSalesState>, _: PayloadAction<PromotionsApi.PromotionDTO>) => {
      state.isCreating = true
    },
    createSalesSuccess: (state: Draft<PromotionsPageSalesState>, _: PayloadAction<void>) => {
      state.isCreating = false
      state.isCreated = true
    },
    createSalesError: (state: Draft<PromotionsPageSalesState>, action: PayloadAction<Error>) => {
      state.isCreating = false
      state.isCreated = true
      state.error = action.payload
    },
    fetchPromotionsModalData: (state: Draft<PromotionsPageSalesState>, _: PayloadAction<void>) => {
      state.isModalLoading = true
    },
    fetchPromotionsModalDataSuccess: (
      state: Draft<PromotionsPageSalesState>,
      action: PayloadAction<FetchPromotionsModalData>
    ) => {
      const { counterparties, franchises, categories, studios } = action.payload

      state.studios = studios
      state.franchises = franchises
      state.counterparties = counterparties
      state.categories = categories
      state.isModalLoading = false
      state.isModalLoaded = true
    },
    fetchPromotionsModalDataError: (state: Draft<PromotionsPageSalesState>, action: PayloadAction<Error>) => {
      state.isModalLoading = false
      state.isModalLoaded = true
      state.error = action.payload
    },
    resetPromotionsModalDate: (state: Draft<PromotionsPageSalesState>) => {
      state.studios = null
      state.franchises = null
      state.counterparties = null
      state.categories = null
      state.isModalLoading = false
      state.isModalLoaded = false
    },
    searchPromotionsProducts: (
      state: Draft<PromotionsPageSalesState>,
      _: PayloadAction<Partial<SearchPromotionsProductParams>>
    ) => {
      state.isCreating = true
    },
    searchPromotionsProductsSuccess: (
      state: Draft<PromotionsPageSalesState>,
      action: PayloadAction<Nullable<ProductsApi.Product[]>>
    ) => {
      state.products = action.payload
      state.isCreating = false
      state.isCreated = true
    },
    searchPromotionsProductsError: (state: Draft<PromotionsPageSalesState>, action: PayloadAction<Error>) => {
      state.isCreating = false
      state.isCreated = true
      state.error = action.payload
    },
    resetPromotionsProducts: (state: Draft<PromotionsPageSalesState>) => {
      state.products = null
    },
    setSelectedStudios: (
      state: Draft<PromotionsPageSalesState>,
      action: PayloadAction<{ id: string; name: string }[]>
    ) => {
      state.selectedStudios = action.payload
    },
    reset: () => initialState,
  },
})
