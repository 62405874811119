import React from 'react'
import { Tabs } from 'antd'

import { usePromotionsTabs } from './promotions-page-tabs.hook'
import { formatPromotionsPageSectionName } from '../../../format/text.format'
import { PromotionsPageSection } from '../promotions-page.types'
import { SalesTabs } from './promotions-page-tabs-sales/promotions-page-tabs-sales.component'
import { CodesTabs } from './promotions-page-tabs-codes/promotions-page-tabs-codes.component'

export const PromotionsTabs = () => {
  const { section, onChangeHandler } = usePromotionsTabs()

  return (
    <>
      <Tabs type="card" activeKey={section} style={{ marginTop: '24px' }} onChange={onChangeHandler}>
        <Tabs.TabPane
          tab={formatPromotionsPageSectionName(PromotionsPageSection.CODES)}
          key={PromotionsPageSection.CODES}
        />
        <Tabs.TabPane
          tab={formatPromotionsPageSectionName(PromotionsPageSection.SALES)}
          key={PromotionsPageSection.SALES}
        />
      </Tabs>
      {section === PromotionsPageSection.CODES && <CodesTabs />}
      {section === PromotionsPageSection.SALES && <SalesTabs />}
    </>
  )
}
