import { Alert, Badge, Button, Col, Flex, Input, Row, Select, Space, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { FC } from 'react'
import {
  CalendarOutlined,
  EditOutlined,
  InboxOutlined,
  InsertRowAboveOutlined,
  SaveOutlined,
  SolutionOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { formatPhoneNumber } from '../../../format/phone.format'
import { formatDays, formatPluralized } from '../../../format/text.format'
import { ScheduleOverviewProps } from './schedule-overview.types'
import './schedule-overview.styles.less'
import { useScheduleOverview } from './schedule-overview.hook'

export const ScheduleOverview: FC<ScheduleOverviewProps> = props => {
  const { schedule, isCommentLoading, isCapacityLoading, ...rest } = props

  const {
    comment,
    isEditing,
    capacity,
    handleEditCommentClick,
    handleInputCommentChange,
    handleAddCommentClick,
    handleSaveCommentClick,
    handleClientClick,
    handleCapacityChange,
    handleSaveCapacityClick,
  } = useScheduleOverview({ schedule, ...rest })

  return (
    <Flex vertical gap={16}>
      <Typography.Title level={4} style={{ margin: 0 }}>
        {schedule.client ? `Длинная запись клиента ${formatPhoneNumber(schedule.client.phone)}` : 'Расписание'}
      </Typography.Title>
      {!comment && !isEditing ? (
        <Button htmlType="button" type="dashed" onClick={handleAddCommentClick} style={{ alignSelf: 'baseline' }}>
          Добавить комментарий
        </Button>
      ) : (
        <Alert
          type="info"
          showIcon
          message={
            <Flex align="center" justify="space-between">
              {isEditing ? (
                <Input
                  value={comment}
                  onChange={handleInputCommentChange}
                  autoFocus
                  style={{ flex: 1, marginRight: 8 }}
                  disabled={isCommentLoading}
                />
              ) : (
                <span>{comment}</span>
              )}
              {isEditing ? (
                <Button
                  type="text"
                  icon={<SaveOutlined style={{ color: '#1677FF' }} />}
                  disabled={isCommentLoading}
                  onClick={handleSaveCommentClick}
                />
              ) : (
                <Button
                  type="text"
                  icon={<EditOutlined style={{ color: '#1677FF' }} />}
                  disabled={isCommentLoading}
                  onClick={handleEditCommentClick}
                />
              )}
            </Flex>
          }
        />
      )}
      <Flex vertical gap={16}>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <InsertRowAboveOutlined className="schedule-overview__icon" />
              Количество
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              {formatPluralized(schedule.totalExercises, ['запись', 'записи', 'записей'])}
            </Typography.Text>
          </Col>
        </Row>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="schedule-overview__icon" />
              Период
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              С {dayjs(schedule.dateFrom).format('DD MMMM YYYY')} по {dayjs(schedule.dateTo).format('DD MMMM YYYY')}
            </Typography.Text>
          </Col>
        </Row>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="schedule-overview__icon" />
              Дни недели
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{schedule.daysOfWeek.map(day => formatDays(day)).join(', ')}</Typography.Text>
          </Col>
        </Row>
        {schedule.direction && (
          <Row align="middle">
            <Col style={{ width: '250px' }}>
              <Typography.Text type="secondary">
                <InboxOutlined className="schedule-overview__icon" />
                Услуга
              </Typography.Text>
            </Col>
            <Col>
              <Tag color="magenta">{schedule.direction?.name}</Tag>
            </Col>
          </Row>
        )}
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="schedule-overview__icon" />
              Вместимость
            </Typography.Text>
          </Col>
          <Col>
            <Space>
              <Input
                value={capacity}
                onChange={handleCapacityChange}
                type="number"
                placeholder="Вместимость"
                style={{ width: '120px' }}
                disabled={isCommentLoading || isCapacityLoading}
              />
              <Button
                type="primary"
                disabled={isCommentLoading || isCapacityLoading || !capacity || capacity < 1}
                loading={isCapacityLoading}
                onClick={handleSaveCapacityClick}
              >
                Применить
              </Button>
            </Space>
          </Col>
        </Row>
        {schedule.client && (
          <>
            <Row align="middle">
              <Col style={{ width: '250px' }}>
                <Typography.Text type="secondary">
                  <SolutionOutlined className="schedule-overview__icon" />
                  Статус оплаты
                </Typography.Text>
              </Col>
              <Col>
                {schedule.payedBookingsCount === schedule.totalExercises ? (
                  <Tag color="green">Оплачено</Tag>
                ) : schedule.payedBookingsCount === 0 ? (
                  <Tag color="orange">Не оплачено</Tag>
                ) : (
                  <Badge
                    status="processing"
                    text={`Оплачено ${schedule.payedBookingsCount} из ${schedule.totalExercises}`}
                  />
                )}
              </Col>
            </Row>
            <Row align="middle">
              <Col style={{ width: '250px' }}>
                <Typography.Text type="secondary">
                  <StarOutlined className="schedule-overview__icon" />
                  Клиент
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Link onClick={handleClientClick}>
                  {formatPhoneNumber(`+${schedule.client.phone}`)}, {schedule.client.name}
                </Typography.Link>
              </Col>
            </Row>
            <Row align="middle">
              <Col style={{ width: '250px' }}>
                <Typography.Text type="secondary">
                  <UserOutlined className="schedule-overview__icon" />
                  Менеджер
                </Typography.Text>
              </Col>
              <Col>
                <Select style={{ width: '177px' }} placeholder="Менеджер" disabled />
              </Col>
            </Row>
          </>
        )}
      </Flex>
    </Flex>
  )
}
