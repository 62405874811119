import React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { PromotionsApi } from '../../../api/types/promotions-api.types'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { Nullable } from '../../../types/lang.types'
import { genPromotionsCodesTableColumns } from './promotions-codes-table.utils'
import { PromotionsCodesTableRow } from './promotions-codes-table.types'

interface PromotionsSalesTableProps {
  codes: Nullable<PromotionsCodesTableRow[]>
  isLoading: boolean
  pagination?: TablePaginationConfig
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
  onEdit: (id: string) => void
  onRemove: (id: string) => void
  onRemovePattern: (id: string, type: PromotionsApi.PromotionsCodesType) => void
}

export const PromotionsCodesTable = ({
  codes,
  isLoading,
  pagination,
  onChangePageSize,
  onRemovePattern,
  onChangePage,
  onEdit,
  onRemove,
}: PromotionsSalesTableProps) => {
  const columns: ColumnsType<PromotionsCodesTableRow> = React.useMemo(
    () => genPromotionsCodesTableColumns({ onEdit, onRemove, onRemovePattern }),
    [onEdit, onRemove, onRemovePattern]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Table loading={isLoading} dataSource={codes || []} rowKey="id" columns={columns} pagination={paginationConfig} />
  )
}
