import { Modal } from 'antd'
import * as React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { NString } from '../../../../types/lang.types'
import { PromotionsSalesCellAction } from './promotions-sales-cell-action.component'

const { confirm } = Modal

interface PromotionsSalesTableActionsProps {
  id: string
  name: NString
  onEdit?: (id: string) => void
  onRemove: (id: string) => void
}

export const PromotionsSalesTableActions = ({ id, name, onEdit, onRemove }: PromotionsSalesTableActionsProps) => {
  const onEditHandler = React.useCallback(() => onEdit?.(id), [id, onEdit])

  const onRemoveHandler = React.useCallback(() => {
    confirm({
      title: `Подтвердите удаление акции ${name}`,
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены, что хотите удалить акцию ${name}`,
      onOk: () => onRemove(id),
    })
  }, [id, name, onRemove])

  return <PromotionsSalesCellAction onEdit={onEditHandler} onRemove={onRemoveHandler} />
}
