import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Input, Typography } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import { formatBookingStatus } from '../../../format/text.format'
import { BookingStatus } from '../../../types/bookings.types'
import { isDefAndNotEmpty, Nullable } from '../../../types/lang.types'
import { getStrEnumValues } from '../../../utils/enum.utils'
import { sortOptionsByLocale } from '../../../utils/options.utils'
import { ScheduleExercisesFilter } from './schedule-exercises-filters.types'

export function genScheduleExercisesFiltersPaymentStatusOptions() {
  return [
    { label: 'Оплачено', value: 'PAID' },
    { label: 'Не оплачено', value: 'UNPAID' },
  ]
}

export function genScheduleExercisesFiltersTimeScopeOptions() {
  return [
    { label: 'Запланированные', value: 'FUTURE' },
    { label: 'Прошедшие', value: 'PAST' },
  ]
}

export function genScheduleExercisesFiltersPaymentTypeOptions() {
  return [
    { label: 'Абонемент', value: 'SUBSCRIPTION' },
    { label: 'Оплата на месте', value: 'ON_PLACE' },
    { label: 'Ожидаем оплату', value: 'RESERVED' },
    { label: 'Единоразовая оплата', value: 'ONE_TIME' },
  ]
}

export function genScheduleExercisesFiltersBookingStatusOptions() {
  const bookingStatuses = getStrEnumValues<BookingStatus>(BookingStatus)

  return bookingStatuses.map(status => ({
    label: formatBookingStatus(status),
    value: status,
  }))
}

function optionsToMenuItems(
  exercisesFilter: ScheduleExercisesFilter,
  options: Nullable<DefaultOptionType[]>,
  type: keyof ScheduleExercisesFilter,
  onSelect: (newValue: string | string[]) => void,
  maxCountOptions?: number,
  closeImmediately: boolean = true
) {
  if (!options) return

  return options.map(option => {
    const filterValue = exercisesFilter[type]
    const isArray = Array.isArray(filterValue)
    const isSelected = isArray && filterValue.find(item => item === option.value)

    const handleClick = (e: React.MouseEvent) => {
      !closeImmediately && e.stopPropagation()
      let newFilterValue: string[] = []

      if (isSelected) {
        newFilterValue = isArray ? filterValue.filter(item => item !== option.value) : []
      } else {
        if (typeof option.value === 'string') {
          if (maxCountOptions && ((Array.isArray(filterValue) && filterValue?.length) || 0) >= maxCountOptions) {
            newFilterValue = [option.value]
          } else {
            newFilterValue = isArray ? [...filterValue, option.value] : [option.value]
          }
        }
      }

      onSelect(newFilterValue)
    }

    return {
      key: option.value,
      label: (
        <Button type="text" className="exercises-filters__item" onClick={handleClick}>
          <span>{option.label}</span>
          {isSelected && <CheckOutlined />}
        </Button>
      ),
    }
  })
}

export function genScheduleExercisesFiltersMenuItems(
  options: {
    trainersOptions: Nullable<DefaultOptionType[]>
    roomsOptions: Nullable<DefaultOptionType[]>
    weekdaysOptions: Nullable<DefaultOptionType[]>
    timeFromOptions: Nullable<DefaultOptionType[]>
    timeToOptions: Nullable<DefaultOptionType[]>
    paymentStatusOptions: Nullable<DefaultOptionType[]>
    paymentTypeOptions: Nullable<DefaultOptionType[]>
    timeScopeOptions: Nullable<DefaultOptionType[]>
    bookingStatusOptions: Nullable<DefaultOptionType[]>
    clientsOptions?: DefaultOptionType[]
    filteredClientsOptions?: DefaultOptionType[]
  },
  isDateFilterOpen: {
    dateFrom: boolean
    dateTo: boolean
  },
  exercisesFilter: ScheduleExercisesFilter,
  longterm: boolean,
  searchQuery: string,
  setSearchQuery: (value: string) => void,
  toggleDateFilter: (filterType: 'from' | 'to') => void,
  handleTrainersChange: (newValue: string | string[]) => void,
  handleClientsChange: (newValue: string | string[]) => void,
  handleSelectFilter: (name: string, value?: string | string[] | undefined) => void
) {
  const {
    trainersOptions,
    roomsOptions,
    weekdaysOptions,
    timeFromOptions,
    timeToOptions,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
    clientsOptions,
    filteredClientsOptions,
  } = options

  return [
    {
      key: '1',
      label: 'Исполнитель',
      children: optionsToMenuItems(
        exercisesFilter,
        [{ label: 'Без исполнителя', value: 'noTrainer' }, ...sortOptionsByLocale(trainersOptions ?? [])],
        'trainerIds',
        handleTrainersChange
      ),
    },
    ...(isDefAndNotEmpty(clientsOptions)
      ? [
          {
            key: '2',
            label: 'Гости',
            children: [
              {
                key: 'search',
                label: (
                  <Input
                    placeholder="Поиск..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    onClick={e => e.stopPropagation()}
                    suffix={
                      <CloseCircleOutlined
                        onClick={e => {
                          e.stopPropagation()
                          setSearchQuery('')
                        }}
                        style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.25)' }}
                      />
                    }
                  />
                ),
              },
              ...(optionsToMenuItems(
                exercisesFilter,
                [
                  ...(filteredClientsOptions && filteredClientsOptions.length > 1
                    ? [{ label: 'Все', value: 'all' }]
                    : []),
                  ...(isDefAndNotEmpty(filteredClientsOptions)
                    ? filteredClientsOptions
                    : [
                        {
                          key: 'not found',
                          label: <Typography.Text type="secondary">Ничего не найдено</Typography.Text>,
                        },
                      ]),
                ],
                'clients',
                handleClientsChange,
                undefined,
                false
              ) || []),
            ],
          },
        ]
      : []),
    ...(isDefAndNotEmpty(roomsOptions)
      ? [
          {
            key: '3',
            label: 'Пространство',
            children: optionsToMenuItems(
              exercisesFilter,
              sortOptionsByLocale(roomsOptions),
              'roomId',
              value => handleSelectFilter('roomId', value),
              1
            ),
          },
        ]
      : []),
    {
      key: '4',
      label: 'День недели',
      children: optionsToMenuItems(
        exercisesFilter,
        weekdaysOptions,
        'dayOfWeek',
        value => handleSelectFilter('dayOfWeek', value),
        1
      ),
    },
    {
      key: '5',
      label: 'Время начала',
      children: optionsToMenuItems(
        exercisesFilter,
        timeFromOptions?.filter(
          option => !isDefAndNotEmpty(exercisesFilter.dayOfWeek) || option.days.includes(exercisesFilter.dayOfWeek?.[0])
        ),
        'timeFrom',
        value => handleSelectFilter('timeFrom', value),
        1
      ),
    },
    {
      key: '6',
      label: 'Время окончания',
      children: optionsToMenuItems(
        exercisesFilter,
        timeToOptions?.filter(
          option => !isDefAndNotEmpty(exercisesFilter.dayOfWeek) || option.days.includes(exercisesFilter.dayOfWeek?.[0])
        ),
        'timeTo',
        value => handleSelectFilter('timeTo', value),
        1
      ),
    },
    {
      key: '7',
      label: (
        <Flex justify="space-between" onClick={() => toggleDateFilter('from')}>
          Дата начала {isDateFilterOpen.dateFrom && <CheckOutlined />}
        </Flex>
      ),
    },
    {
      key: '8',
      label: (
        <Flex justify="space-between" onClick={() => toggleDateFilter('to')}>
          Дата окончания {isDateFilterOpen.dateTo && <CheckOutlined />}
        </Flex>
      ),
    },
    ...(longterm
      ? [
          {
            key: '9',
            label: '',
            type: 'divider',
          },
          {
            key: '10',
            label: 'Статус оплаты',
            children: optionsToMenuItems(
              exercisesFilter,
              paymentStatusOptions,
              'bookingPaymentStatus',
              value => handleSelectFilter('bookingPaymentStatus', value),
              1
            ),
          },
          {
            key: '11',
            label: 'Метод оплаты',
            children: optionsToMenuItems(
              exercisesFilter,
              paymentTypeOptions,
              'paymentType',
              value => handleSelectFilter('paymentType', value),
              1
            ),
          },
          {
            key: '12',
            label: 'Статус записи',
            children: optionsToMenuItems(
              exercisesFilter,
              timeScopeOptions,
              'timeScope',
              value => handleSelectFilter('timeScope', value),
              1
            ),
          },
          {
            key: '13',
            label: 'Статус бронирования',
            children: optionsToMenuItems(exercisesFilter, bookingStatusOptions, 'bookingStatuses', value =>
              handleSelectFilter('bookingStatuses', value)
            ),
          },
        ]
      : []),
  ]
}
