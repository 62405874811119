import React, { useState } from 'react'
import { Flex, Form, FormInstance, Switch, Typography } from 'antd'
import { SwitchSize } from 'antd/es/switch'

interface ClickableLabelSwitchProps {
  name: string
  label: string
  form: FormInstance
  size?: SwitchSize
  onChange?: () => void
}

export const ClickableLabelSwitch = ({ form, name, label, size = 'small', onChange }: ClickableLabelSwitchProps) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    const newCheckedValue = !isChecked
    onChange?.()
    setIsChecked(newCheckedValue)
    form.setFieldValue(name, newCheckedValue)
  }

  return (
    <Form.Item name={name}>
      <Flex style={{ cursor: 'pointer' }} onClick={handleChange} gap={'small'} align="center">
        <Switch checked={isChecked} size={size} />
        <Typography>{label}</Typography>
      </Flex>
    </Form.Item>
  )
}
