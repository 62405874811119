import { Flex, Button } from 'antd'

import { formatPluralized } from '../../../../format/text.format'

interface Props {
  isLoading: boolean
  totalExercises?: number
  onRemoveExercises: () => void
}

export const ScheduleModalEditExercisesCancellation: React.FC<Props> = props => {
  const { isLoading, totalExercises, onRemoveExercises } = props

  return (
    <Flex gap={16} vertical align="baseline">
      <p style={{ margin: 0 }}>
        {`Вы собираетесь отменить ${formatPluralized(totalExercises || 0, [
          'запись',
          'записи',
          'записей',
        ])}. При этом оплата за них останется на балансе клиента и их можно
            использовать на оплату других аналогичных записей клиента.`}
      </p>
      <Button htmlType="button" type="primary" loading={isLoading} onClick={onRemoveExercises}>
        Применить изменения для {formatPluralized(totalExercises || 0, ['записи', 'записей', 'записей'])}
      </Button>
    </Flex>
  )
}
