import { DefaultOptionType } from 'antd/es/select'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTheme } from '../../../hooks/use-theme.hook'
import { Nullable } from '../../../types/lang.types'
import { ScheduleEditExercisesFormValues } from './schedule-modal-edit.types'

interface Props {
  initialValues: ScheduleEditExercisesFormValues
  longterm?: boolean
  paymentTypesOptions?: DefaultOptionType[]
  preSelectedPaymentType?: string
}

export function useScheduleModalEdit(props: Props) {
  const { paymentTypesOptions, initialValues, longterm, preSelectedPaymentType } = props

  const { theme } = useTheme()

  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const [paymentType, setPaymentType] = useState<Nullable<DefaultOptionType>>(null)

  const [visiblePanels, setVisiblePanels] = useState<string>()

  const menuItems = useMemo(() => {
    const allItems = [
      { key: '1', label: 'Отменить записи' },
      ...(Object.values(initialValues).some(value => value !== undefined)
        ? [{ key: '2', label: 'Измененить записи: день недели, время и другое' }]
        : []),
      ...(longterm ? [{ key: '3', label: 'Оплатить записи' }] : []),
      { key: '4', label: 'Изменить комментарий' },
      ...(!longterm ? [{ key: '5', label: 'Действия с гостями' }] : []),
    ]

    return allItems.filter(item => item.key !== visiblePanels)
  }, [initialValues, longterm, visiblePanels])

  // const studioId = Form.useWatch(['studio', 'newValue'], form)

  const onCollapseChangeHandler = useCallback((keys: string | string[]) => {
    setActiveKeys(Array.isArray(keys) ? keys : [keys])
  }, [])

  const onMenuClick = useCallback(
    ({ key }: { key: string }) => {
      setVisiblePanels(key)
      onCollapseChangeHandler([key])
    },
    [onCollapseChangeHandler]
  )

  const onChangePaymentTypeHandler = useCallback(
    (value: string) => {
      const selected = paymentTypesOptions?.find(option => option.value === value)
      setPaymentType(selected)
    },
    [paymentTypesOptions]
  )

  // If payment type was previously selected, set the visibility of the panels and activate key
  useEffect(() => {
    if (preSelectedPaymentType) {
      setVisiblePanels('3')
      setActiveKeys(['3'])
      onChangePaymentTypeHandler(preSelectedPaymentType)
    }
  }, [onChangePaymentTypeHandler, paymentType, preSelectedPaymentType])

  return {
    theme,
    activeKeys,
    visiblePanels,
    // studioId,
    paymentType,
    menuItems,
    onMenuClick,
    onCollapseChangeHandler,
    onChangePaymentTypeHandler,
  }
}
