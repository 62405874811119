import { Button, Col, Flex, Form, Input, Select, Space, Switch, Typography } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useEffect, useState } from 'react'

import { isDef } from '../../../types/lang.types'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { useTheme } from '../../../hooks/use-theme.hook'
import { QuestionTooltip } from '../../ui/question-tooltip/question-tooltip.component'
import { InputPhoneNumber } from '../../controls/input-phone-number/input-phone-number.component'
import { FranchisesFormAcceptingPayment } from './franchises-form-accepting-payments/franchises-form-accepting-payments.component'
import { FranchisesFormPrintingReceipts } from './franchises-form-printing-receipts/franchises-form-printing-receipts.component'
import { useFranchisesForm } from './franchises-form.hook'
import { FranchisesFormProps } from './franchises-form.types'
import { genFranchisesFormInitialValues } from './franchises-form.utils'
import './franchises-form.styles.less'

export const FranchisesForm = (props: FranchisesFormProps) => {
  const { form, isLoading, studiosOptions, isUpdating, customersOptions, terminals, tills, isEdit } = props
  const { onFinish, onRemoveFranchise } = props

  const {
    phoneValidationRules,
    emailValidationRules,
    customerNameValidationRules,
    currencyOptions,
    optionsSelect,
    countriesOptions,
    validateFranchiseCommissionInput,
  } = useFranchisesForm()

  const { theme } = useTheme()

  const franchiseValue = useWatch('mainForOnlinePayments', form)
  const [isFranchise, setIsFranchise] = useState(false)
  const sellCommission = useWatch('sellCommission', form)
  const refundCommission = useWatch('refundCommission', form)
  const isAvailableStudios = studiosOptions && studiosOptions?.length > 0

  useEffect(() => {
    if (franchiseValue && !isFranchise) {
      setIsFranchise(prev => !prev)
    }
  }, [franchiseValue])

  useEffect(() => {
    if (isDef(sellCommission) && isDef(refundCommission)) {
      const bothZero = sellCommission <= 0 && refundCommission <= 0
      setIsShowComission(bothZero)
      form.setFieldValue('isShowComission', bothZero)
    }
  }, [sellCommission, refundCommission, form])

  const onChangeFranchise = () => {
    form.setFieldValue('mainForOnlinePayments', !franchiseValue)
    setIsFranchise(prev => !prev)
  }

  const isShowComissionValue = useWatch('isShowComission', form)
  const [isShowComission, setIsShowComission] = useState<boolean>()

  const onChangeIsShowComission = () => {
    form.setFieldValue('isShowComission', !isShowComissionValue)
    setIsShowComission(prev => !prev)
    form.setFieldValue('sellCommission', !isShowComission ? 0 : 1)
    form.setFieldValue('refundCommission', !isShowComission ? 0 : 1)
  }

  const currentTheme = theme === 'light' ? 'light' : 'dark'

  const receiptsSwitch = useWatch('receiptsEnabled', form)
  const [receiptsSwitchValue, setReceiptsSwitchValue] = useState(false)

  const onChangeReceiptsSwitchValue = () => {
    form.setFieldValue('receiptsEnabled', !receiptsSwitchValue)
    setReceiptsSwitchValue(prev => !prev)
  }

  const onChangePhoneHandler = (value: string) => {
    form.setFieldValue('phone', value)
  }

  useEffect(() => {
    if (receiptsSwitch && !receiptsSwitchValue) {
      form.setFieldValue('receiptsEnabled', true)
      setReceiptsSwitchValue(prev => !prev)
    }
  }, [receiptsSwitch])

  if (isLoading) {
    return (
      <div className="franchises-form-container" style={{ height: '100%' }}>
        <PageLoader />
      </div>
    )
  }

  return (
    <Form
      initialValues={genFranchisesFormInitialValues()}
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <Flex vertical gap={24} className="franchises-form-container">
        <div className={`franchises-form franchises-form__top ${currentTheme}`}>
          <Col className="franchises-form__top-basic-information">
            <Typography.Title level={4}>Основная информация</Typography.Title>
            <Flex gap={15}>
              <Form.Item
                style={{ width: 422 }}
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Введите название компании' }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                style={{ width: 272 }}
                label="Страна"
                name="country"
                rules={[{ required: true, message: 'Выберите страну' }]}
              >
                <Select options={countriesOptions} size="large" placeholder="Выберите страну" />
              </Form.Item>
              <Form.Item style={{ width: 341 }} label="Вид юридического лица" name="customerType">
                <Select defaultValue="UNKNOWN" size="large" options={optionsSelect} />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item
                style={{ width: 422 }}
                label="Руководитель"
                name="customerName"
                rules={customerNameValidationRules}
              >
                <Select size="large" placeholder="Выберите сотрудника" options={customersOptions} />
              </Form.Item>
              <Form.Item
                style={{ width: 273 }}
                label="Номер телефона"
                name="phone"
                rules={phoneValidationRules}
                validateTrigger={['onBlur']}
              >
                <InputPhoneNumber
                  value={form.getFieldValue('phone')}
                  size="large"
                  onChange={onChangePhoneHandler}
                  onBlur={() => form.validateFields(['phone'])}
                  onFocus={() =>
                    form.setFields([
                      {
                        name: 'phone',
                        errors: [],
                      },
                    ])
                  }
                />
              </Form.Item>
              <Form.Item style={{ width: 340 }} label="Email" name="email" rules={emailValidationRules}>
                <Input size="large" placeholder="Email" />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item style={{ width: 190 }} label="Валюта" name="currency" rules={[{ required: false }]}>
                <Select size="large" options={currencyOptions} defaultValue="RUB" />
              </Form.Item>
              <Form.Item
                style={{ width: 216 }}
                label="ИНН"
                name="inn"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (value && (value.length === 10 || value.length === 12)) {
                        return Promise.resolve()
                      }
                      return Promise.reject('ИНН должен состоять из 10 или 12 цифр')
                    },
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Flex>
          </Col>
          <Flex className="franchises-form__top-switches">
            <Col onClick={onChangeFranchise}>
              <Form.Item name="mainForOnlinePayments">
                <Flex style={{ cursor: 'pointer' }} gap={6} align="center">
                  <Switch defaultValue={isFranchise} value={isFranchise} size="small" />
                  <Typography>Является головной компанией (франшизный режим)</Typography>
                  <QuestionTooltip title="Включите этот режим, если у вас франшизная сеть. Этот режим позволяет создавать единые абонементы для каждой вашей точки, формировать агентские чеки и отчёты. Головная локация будет получать оплату за абонементы во все локации на свой счёт. Затем с помощью агентских чеков и зачета аванса депозит с абонемента будет автоматически распределяться по франшизам. Вы сможете ежедневно скачивать отчёты по бухгалтерскому учету агентских выплат." />
                </Flex>
              </Form.Item>
            </Col>
            {isFranchise && (
              <Flex style={{ marginLeft: 16, marginBottom: 8, width: 500 }} vertical>
                {isAvailableStudios && (
                  <Form.Item
                    style={{ flex: 1 }}
                    label='Локации по умолчанию для услуг или абонемнетов "во все компании"'
                    name="mainStudioId"
                    rules={[{ required: true }]}
                  >
                    <Select size="middle" options={studiosOptions} placeholder={'Не выбрано'} />
                  </Form.Item>
                )}
                <Form.Item name="isShowComission">
                  <Flex
                    style={{ cursor: 'pointer', marginTop: isAvailableStudios ? 16 : 0 }}
                    gap={6}
                    align="center"
                    onClick={onChangeIsShowComission}
                  >
                    <Switch checked={!isShowComission} size="small" />
                    <Typography>Взымать не возвращаемые комиссии</Typography>
                    <QuestionTooltip
                      title={`Включите это свойство, чтобы автоматически взимать комиссии, например, при покупке или возврате. Эти комиссии помогут вам компенсировать затраты на эквайринг или другие издержки. Если у вас включены налоговые чеки, сумма этих комиссий будет отправлена в отдельном чеке с текстом "Невозвращаемая комиссия за сервисное обслуживание".`}
                    />
                  </Flex>
                </Form.Item>
                <Flex
                  vertical
                  style={{
                    width: 500,
                    marginTop: 8,
                  }}
                  gap={'small'}
                >
                  <Form.Item
                    style={{ flex: 1 }}
                    name="sellCommission"
                    label="Комиссия при оплате услуги или абонемента"
                    rules={[{ required: true, message: 'Введите комиссию' }]}
                    initialValue={0}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    style={{ flex: 1 }}
                    name="refundCommission"
                    label="Комиссия при отмене транзакции услуги или абонемента"
                    rules={[{ required: true, message: 'Введите комиссию' }]}
                    initialValue={0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Flex>
              </Flex>
            )}
            <Form.Item name="receiptsEnabled">
              <Flex gap={8} style={{ cursor: 'pointer' }} align="center" onClick={onChangeReceiptsSwitchValue}>
                <Switch value={receiptsSwitchValue} size="small" />
                <Typography>Включить печать чеков ОФД</Typography>
                <QuestionTooltip title="Включите возможность подключения офлайн или онлайн кассы для печати налоговых чеков после каждой успешной транзакции или посещения по абонементу." />
              </Flex>
            </Form.Item>
          </Flex>
        </div>
        <Flex className={`franchises-form__bottom`} gap={21}>
          <FranchisesFormAcceptingPayment terminals={terminals || []} form={form} theme={currentTheme} />
          {receiptsSwitch && <FranchisesFormPrintingReceipts theme={currentTheme} tills={tills || []} form={form} />}
        </Flex>
        <Space size={16} style={{ justifyContent: 'flex-end' }}>
          {isEdit && (
            <Button type="text" danger disabled={isUpdating} loading={isUpdating} onClick={onRemoveFranchise}>
              Удалить компанию
            </Button>
          )}
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            Сохранить
          </Button>
        </Space>
      </Flex>
    </Form>
  )
}
