import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'

import { genPromotionsPagePath } from '../../../../format/path.format'
import { PromotionsPageSection } from '../../promotions-page.types'
import { usePromotionsPageParams } from '../../promotions-page-hooks/promotions-page.hook'
import { genPaginationConfig } from '../../../../utils/pagination.utils'
import {
  getPromotionsCodesForTableRow,
  getPromotionsCodesIsLoading,
  getPromotionsCodeTotalElements,
} from '../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes.selectors'
import { promotionsPageCodesActions } from '../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes.slice'
import { PromotionsApi } from '../../../../api/types/promotions-api.types'

export const useCodesTabs = () => {
  const dispatch = useDispatch()

  const { page, size } = usePromotionsPageParams()
  const { push } = useHistory()

  const codes = useSelector(getPromotionsCodesForTableRow)
  const isLoading = useSelector(getPromotionsCodesIsLoading)
  const totalElements = useSelector(getPromotionsCodeTotalElements)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const onEditHandler = React.useCallback((id: string): void => {
    notification.error({ message: 'Пока не работает' })
  }, [])

  const onRemoveHandler = React.useCallback(
    (id: string): void => {
      dispatch(promotionsPageCodesActions.removeCodes({ id }))
    },
    [dispatch]
  )

  const onRemovePatternHandler = React.useCallback(
    (id: string, type: PromotionsApi.PromotionsCodesType): void => {
      dispatch(promotionsPageCodesActions.removePatternCodes({ id }))
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.CODES }, { page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.CODES }, { page, size: pageSize }))
    },
    [page, push]
  )

  const onVisiblePastCodesHandler = React.useCallback(
    (value: boolean) => {
      dispatch(promotionsPageCodesActions.fetchAllCodes({ page, size, isShowPast: !value }))
    },
    [dispatch, page, size]
  )

  React.useEffect(() => {
    dispatch(promotionsPageCodesActions.fetchAllCodes({ page, size }))
  }, [dispatch, page, size])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageCodesActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    codes,
    pagination,
    onEditHandler,
    onRemoveHandler,
    onChangePageHandler,
    onRemovePatternHandler,
    onChangePageSizeHandler,
    onVisiblePastCodesHandler,
  }
}
