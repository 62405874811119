import { Button, Collapse, Dropdown, Flex, Form, Menu, Typography } from 'antd'
import clsx from 'clsx'
import { PlusCircleOutlined } from '@ant-design/icons'

import { ScheduleModalEditProps } from './schedule-modal-edit.types'
import { useScheduleModalEdit } from './schedule-modal-edit.hook'
import { ScheduleModalEditClientsActions } from './schedule-modal-edit-clients-actions/schedule-modal-edit-clients-actions.component'
import { ScheduleModalEditComment } from './schedule-modal-edit-comment/schedule-modal-edit-comment.component'
import { ScheduleModalEditExercisesPayment } from './schedule-modal-edit-exercises-payment/schedule-modal-edit-exercises-payment.component'
import { ScheduleModalEditExercisesUpdating } from './schedule-modal-edit-exercises-updating/schedule-modal-edit-exercises-updating.component'
import { ScheduleModalEditExercisesCancellation } from './schedule-modal-edit-exercises-cancellation/schedule-modal-edit-exercises-cancellation.component'
import { ScheduleModal } from '../schedule-modal/schedule-modal.component'
import './schedule-modal-edit.styles.less'

export const ScheduleModalEdit: React.FC<ScheduleModalEditProps> = props => {
  const {
    isLoading,
    form,
    studioId,
    initialValues,
    totalExercises,
    longterm,
    longtermClientPhone,
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    paymentTypesOptions,
    preSelectedPaymentType,
    clients,
    scheduleId,
    exercisesFilter,
    onCancel,
    onEditExercises,
    onEditExercisesComment,
    onPayExercises,
    onRemoveExercises,
    onCreateBookings,
    onCancelBookings,
  } = props

  const {
    theme,
    activeKeys,
    visiblePanels,
    paymentType,
    menuItems,
    onMenuClick,
    onCollapseChangeHandler,
    onChangePaymentTypeHandler,
  } = useScheduleModalEdit({
    paymentTypesOptions,
    initialValues,
    longterm,
    preSelectedPaymentType,
  })

  const menu = <Menu onClick={onMenuClick} items={menuItems} />

  return (
    <ScheduleModal
      title="Массовые действия с записями"
      width={720}
      onCancel={onCancel}
      footer={
        <Flex justify="center">
          <Button loading={isLoading} onClick={onCancel}>
            Отменить
          </Button>
        </Flex>
      }
    >
      <Form disabled={isLoading} form={form}>
        <Flex vertical gap={16}>
          {visiblePanels === '1' && (
            <Collapse
              className={clsx('schedule-modal-edit__collapse', 'schedule-modal-edit__collapse--warning', {
                'schedule-modal-edit__collapse--open': activeKeys.includes('1'),
                'schedule-modal-edit__collapse--dark': theme === 'dark',
              })}
              bordered={false}
              expandIconPosition="end"
              activeKey={activeKeys}
              onChange={onCollapseChangeHandler}
              items={[
                {
                  key: '1',
                  label: (
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Отмена записей
                    </Typography.Title>
                  ),
                  children: (
                    <ScheduleModalEditExercisesCancellation
                      isLoading={isLoading}
                      totalExercises={totalExercises}
                      onRemoveExercises={onRemoveExercises}
                    />
                  ),
                },
              ]}
            />
          )}
          {visiblePanels === '2' && (
            <Collapse
              className="schedule-modal-edit__collapse"
              ghost
              expandIconPosition="end"
              activeKey={activeKeys}
              onChange={onCollapseChangeHandler}
              items={[
                {
                  key: '2',
                  label: (
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Изменение записей: день недели, время и другое
                    </Typography.Title>
                  ),
                  children: (
                    <ScheduleModalEditExercisesUpdating
                      form={form}
                      studioId={studioId}
                      isLoading={isLoading}
                      initialValues={initialValues}
                      totalExercises={totalExercises}
                      studiosRoomsOptions={studiosRoomsOptions}
                      trainersOptions={trainersOptions}
                      onEditExercises={onEditExercises}
                    />
                  ),
                },
              ]}
            />
          )}
          {visiblePanels === '3' && (
            <Collapse
              className="schedule-modal-edit__collapse"
              ghost
              expandIconPosition="end"
              activeKey={activeKeys}
              onChange={onCollapseChangeHandler}
              items={[
                {
                  key: '3',
                  label: (
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Оплатить записи
                    </Typography.Title>
                  ),
                  children: (
                    <ScheduleModalEditExercisesPayment
                      isLoading={isLoading}
                      form={form}
                      paymentType={paymentType}
                      paymentTypesOptions={paymentTypesOptions}
                      longtermClientPhone={longtermClientPhone}
                      onChangePaymentType={onChangePaymentTypeHandler}
                      onPayExercises={onPayExercises}
                    />
                  ),
                },
              ]}
            />
          )}
          {visiblePanels === '4' && (
            <Collapse
              className="schedule-modal-edit__collapse"
              ghost
              expandIconPosition="end"
              activeKey={activeKeys}
              onChange={onCollapseChangeHandler}
              items={[
                {
                  key: '4',
                  label: (
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Изменить комментарий
                    </Typography.Title>
                  ),
                  children: (
                    <ScheduleModalEditComment
                      isLoading={isLoading}
                      form={form}
                      totalExercises={totalExercises}
                      onEditExercisesComment={onEditExercisesComment}
                    />
                  ),
                },
              ]}
            />
          )}
          {visiblePanels === '5' && (
            <Collapse
              className="schedule-modal-edit__collapse"
              ghost
              expandIconPosition="end"
              activeKey={activeKeys}
              onChange={onCollapseChangeHandler}
              items={[
                {
                  key: '5',
                  label: (
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Действия с гостями
                    </Typography.Title>
                  ),
                  children: (
                    <ScheduleModalEditClientsActions
                      form={form}
                      isLoading={isLoading}
                      totalExercises={totalExercises}
                      exercisesFilter={exercisesFilter}
                      scheduleId={scheduleId}
                      clients={clients}
                      onPayExercises={onPayExercises}
                      onCreateBookings={onCreateBookings}
                      onCancelBookings={onCancelBookings}
                    />
                  ),
                },
              ]}
            />
          )}
          {menuItems.length > 0 && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button
                className="schedule-modal-edit__button"
                htmlType="button"
                type="dashed"
                icon={<PlusCircleOutlined />}
              >
                Выберите действие
              </Button>
            </Dropdown>
          )}
        </Flex>
      </Form>
    </ScheduleModal>
  )
}
