import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Form, FormInstance, Input, Row, Select, Spin } from 'antd'
import { useState } from 'react'

import { apiV2 } from '../../../../api/api-v2'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { ClientsAutocompleteContainer } from '../../../../containers/clients-autocomplete/clients-autocomplete.container'
import { formatPhoneNumber } from '../../../../format/phone.format'
import { formatPluralized } from '../../../../format/text.format'
import { mapExercisesTimetablePaymentTypesToOptions } from '../../../../mapping/payment-types.mapping'
import { ScheduleExercisesFilter } from '../../schedule-exercises-filters/schedule-exercises-filters.types'
import { ScheduleEditExercisesFormValues } from '../schedule-modal-edit.types'
import { genScheduleModalEditClientsActionsPhoneValidationRules } from './schedule-modal-edit-clients-actions.utils'

interface Props {
  form: FormInstance<ScheduleEditExercisesFormValues>
  isLoading: boolean
  totalExercises?: number
  exercisesFilter?: ScheduleExercisesFilter
  scheduleId?: string
  clients?: { id: string; phone: string }[]
  onPayExercises: (phone: string, paymentType?: ExercisesTimetableApi.ExercisesTimetablePaymentType) => void
  onCreateBookings?: () => void
  onCancelBookings?: (clientId: string) => void
}

export const ScheduleModalEditClientsActions: React.FC<Props> = props => {
  const { form, isLoading, totalExercises, exercisesFilter, scheduleId, clients } = props
  const { onPayExercises, onCreateBookings, onCancelBookings } = props

  const [loading, setLoading] = useState<Record<string, boolean>>({})
  const [paymenMethods, setPaymenMethods] = useState<
    Record<string, ExercisesTimetableApi.ExercisesTimetablePaymentType[]>
  >({})
  const [selectedMethods, setSelectedMethods] = useState<Record<string, string>>({})

  const [isClientBlockVisible, setIsClientBlockVisible] = useState(false)

  const phoneValidationRules = genScheduleModalEditClientsActionsPhoneValidationRules()

  const handlePaymenMethodSelectOpen = async (clientId: string, phone: string) => {
    if (paymenMethods[clientId] || !scheduleId) return

    setLoading(prev => ({ ...prev, [clientId]: true }))
    try {
      const paymentMethods = await apiV2.exercisesTimetableService.fetchPaymentTypesByTimetableId(scheduleId, {
        ...exercisesFilter,
        phone,
      })
      setPaymenMethods(prev => ({ ...prev, ...(paymentMethods?.data && { [clientId]: paymentMethods?.data }) }))
    } catch (error) {
      console.error('Ошибка загрузки методов оплаты:', error)
    } finally {
      setLoading(prev => ({ ...prev, [clientId]: false }))
    }
  }

  const handlePaymentMethodChange = (clientId: string, value: string) => {
    setSelectedMethods(prev => ({ ...prev, [clientId]: value }))
  }

  const handlePayExercises = (clientId: string, phone: string) => {
    const clientPaymenMethods = paymenMethods[clientId]
    const clientPaymenMethod = selectedMethods[clientId]

    const selectedPaymentType = clientPaymenMethods?.find(type =>
      clientPaymenMethod === 'ONE_TIME'
        ? type.paymentType === 'ONE_TIME'
        : type.clientSubscriptionId === clientPaymenMethod
    )

    onPayExercises(phone, selectedPaymentType)
  }

  const handleCreateBookings = async () => {
    try {
      await form.validateFields(['clientPhone'])
      onCreateBookings && onCreateBookings()
    } catch (errorInfo) {}
  }

  return (
    <Flex vertical gap={24} align="baseline">
      <Flex vertical gap={8} align="baseline">
        {clients?.map((client, index) => (
          <Row gutter={16} key={client.id} align="bottom">
            <Col>
              <Form.Item
                style={{ width: '204px' }}
                className="schedule-modal-edit__form-item"
                label={`Гость ${index + 1}`}
              >
                <Input value={formatPhoneNumber(`+${client.phone}`)} disabled size="large" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ width: '208px' }} className="schedule-modal-edit__form-item" label="Метод оплаты">
                <Select
                  placeholder="Выберите метод оплаты"
                  size="large"
                  loading={loading[client.id]}
                  disabled={isLoading}
                  onDropdownVisibleChange={open => open && handlePaymenMethodSelectOpen(client.id, client.phone)}
                  onChange={value => handlePaymentMethodChange(client.id, value)}
                >
                  {loading[client.id] ? (
                    <Select.Option disabled>
                      <Spin size="small" />
                    </Select.Option>
                  ) : (
                    mapExercisesTimetablePaymentTypesToOptions(paymenMethods[client.id])?.map(method => (
                      <Select.Option key={method.value} value={method.value}>
                        {method.label}
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            </Col>
            {selectedMethods[client.id] && (
              <Col>
                <Button size="large" loading={isLoading} onClick={() => handlePayExercises(client.id, client.phone)}>
                  Применить
                </Button>
              </Col>
            )}
            <Col>
              {onCancelBookings && (
                <Button icon={<DeleteOutlined />} size="large" danger onClick={() => onCancelBookings(client.id)} />
              )}
            </Col>
          </Row>
        ))}
      </Flex>
      {isClientBlockVisible && (
        <Flex vertical gap={8} align="baseline">
          <Form.Item
            label="Выберите Гостя"
            name="clientPhone"
            rules={phoneValidationRules}
            validateTrigger="onBlur"
            className="schedule-modal-edit__form-item"
          >
            <ClientsAutocompleteContainer
              name="clientPhone"
              size="large"
              form={form}
              disabled={isLoading}
              showButtonAddClient={false}
            />
          </Form.Item>
          <Button htmlType="button" type="primary" loading={isLoading} onClick={handleCreateBookings}>
            Применить изменения для {formatPluralized(totalExercises || 0, ['записи', 'записей', 'записей'])}
          </Button>
        </Flex>
      )}
      {!isClientBlockVisible && <Button onClick={() => setIsClientBlockVisible(true)}>Добавить Гостя</Button>}
    </Flex>
  )
}
