import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import {
  genScheduleOverview,
  genScheduleExercises,
  mapExercisesFilterOptionsToWeekdaysOptions,
  mapExercisesFilterOptionsToTimeOptions,
  mapExercisesFilterOptionsToClientsOptions,
} from '../../../../mapping/exercises-timetable.mapping'
import { mapTrainersToOptions } from '../../../../mapping/trainers.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../../mapping/studios.mapping'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genScheduleManagementPagePeekAboutScheduleInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.timetable

const genScheduleManagementPagePeekAboutExercisesInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.exercises

const getScheduleManagementPagePeekAboutTrainersInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.trainers

export const genScheduleManagementPagePeekAboutStudios = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.studios

export const genScheduleManagementPagePeekAboutIsExercisesLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.isExercisesLoading

export const genScheduleManagementPagePeekAboutIsTimetableLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.isTimetableLoading

export const genScheduleManagementPagePeekAboutIsCommentLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.isCommentLoading

export const genScheduleManagementPagePeekAboutIsCapacityLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.isCapacityLoading

export const genScheduleManagementPagePeekAboutExercisesFilter = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.exercisesFilter

export const genScheduleManagementPagePeekAboutExercisesFilterOptions = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.exercisesFilterOptions

export const genScheduleManagementPagePeekAboutSchedule = createSelector(
  genScheduleManagementPagePeekAboutScheduleInternal,
  genScheduleOverview
)

export const genScheduleManagementPagePeekAboutBookingClients = createSelector(
  genScheduleManagementPagePeekAboutScheduleInternal,
  schedule => schedule?.bookingClients
)

export const genScheduleManagementPagePeekAboutExercises = createSelector(
  genScheduleManagementPagePeekAboutExercisesInternal,
  exercises => genScheduleExercises(exercises?.content)
)

export const genScheduleManagementPagePeekAboutExercisesTotalElement = createSelector(
  genScheduleManagementPagePeekAboutExercisesInternal,
  exercises => exercises?.totalElements
)

export const genScheduleManagementPagePeekAboutTrainersOptions = createSelector(
  getScheduleManagementPagePeekAboutTrainersInternal,
  trainers => mapTrainersToOptions(trainers?.content)
)

export const genScheduleManagementPagePeekAboutFilterTrainersOptions = createSelector(
  genScheduleManagementPagePeekAboutScheduleInternal,
  schedule =>
    schedule?.trainers?.map(trainer => ({
      label: trainer.name,
      value: trainer.id,
    }))
)

export const genScheduleManagementPagePeekAboutStudiosOptions = createSelector(
  genScheduleManagementPagePeekAboutStudios,
  studios => mapStudiosToOptions(studios?.content)
)

export const genScheduleManagementPagePeekAboutStudiosRoomsOptions = createSelector(
  genScheduleManagementPagePeekAboutStudios,
  studios => mapStudiosToRoomsOptions(studios?.content)
)

export const genScheduleManagementPagePeekAboutFilterRoomsOptions = createSelector(
  genScheduleManagementPagePeekAboutScheduleInternal,
  schedule =>
    schedule?.rooms?.map(room => ({
      label: room.name,
      value: room.id,
    }))
)

export const genScheduleManagementPagePeekAboutWeekdaysOptions = createSelector(
  genScheduleManagementPagePeekAboutExercisesFilterOptions,
  mapExercisesFilterOptionsToWeekdaysOptions
)

export const genScheduleManagementPagePeekAboutWeekdaysTimeFromOptions = createSelector(
  genScheduleManagementPagePeekAboutExercisesFilterOptions,
  getCurrentStudioOffset,
  (exercisesFilterOptions, studioOffset) =>
    mapExercisesFilterOptionsToTimeOptions(exercisesFilterOptions, 'from', studioOffset)
)

export const genScheduleManagementPagePeekAboutWeekdaysTimeToOptions = createSelector(
  genScheduleManagementPagePeekAboutExercisesFilterOptions,
  getCurrentStudioOffset,
  (exercisesFilterOptions, studioOffset) =>
    mapExercisesFilterOptionsToTimeOptions(exercisesFilterOptions, 'to', studioOffset)
)

export const genScheduleManagementPagePeekAboutClientsOptions = createSelector(
  genScheduleManagementPagePeekAboutExercisesFilterOptions,
  mapExercisesFilterOptionsToClientsOptions
)
