import React from 'react'
import { Checkbox } from 'antd'

import { useSalesTabs } from './promotions-page-tabs-sales.hook'
import { PromotionsSalesTable } from '../../../../components/promotions/promotions-sales-table/promotions-sales-table.component'
import { ContractsTabsContainer } from '../../../../components/contracts/contracts-tabs-container/contracts-tabs-container.component'

export const SalesTabs = () => {
  const {
    isLoading,
    sales,
    onVisiblePastSalesHandler,
    pagination,
    onChangePageSizeHandler,
    onChangePageHandler,
    onRemoveHandler,
    onEditHandler,
  } = useSalesTabs()

  return (
    <ContractsTabsContainer>
      <Checkbox onChange={e => onVisiblePastSalesHandler(e.target.checked)}>Скрывать прошедшие</Checkbox>
      <PromotionsSalesTable
        sales={sales}
        isLoading={isLoading}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        onChangePage={onChangePageHandler}
        onChangePageSize={onChangePageSizeHandler}
        pagination={pagination}
      />
    </ContractsTabsContainer>
  )
}
