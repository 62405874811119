import qs from 'qs'

import {
  PROMOTIONS_PAGE_SIZE,
  PromotionsPageQueryParams,
  PromotionsPageSection,
  PromotionsPageUrlParams,
} from './promotions-page.types'
import { validateStrEnumValue } from '../../utils/enum.utils'
import { isDef, isString, NString } from '../../types/lang.types'

export function genPromotionsPageUrlParams(params: PromotionsPageUrlParams) {
  const section =
    validateStrEnumValue<PromotionsPageSection>(PromotionsPageSection, params.section) || PromotionsPageSection.SALES

  return { section }
}

export function genPromotionsPageQueryParams(search: NString): PromotionsPageQueryParams {
  const queryParams = isDef(search) ? qs.parse(search, { ignoreQueryPrefix: true }) : null

  const page = isString(queryParams?.page) ? Number(queryParams?.page) : null
  const size = isString(queryParams?.size) ? Number(queryParams?.size) : PROMOTIONS_PAGE_SIZE

  return {
    size,
    page,
  }
}
