import * as React from 'react'
import { Alert, Button, Flex, Form } from 'antd'
import { useMemo } from 'react'

import { ScheduleModalAddProps } from './schedule-modal-add.types'
import { ScheduleSlots } from '../schedule-slots/schedule-slots.component'
import { genScheduleFormInitialValues, isCheckSlotsEmpty } from '../schedule-form/schedule-form.utils'
import { ScheduleModal } from '../schedule-modal/schedule-modal.component'

export const ScheduleModalAdd: React.FC<ScheduleModalAddProps> = props => {
  const { form, isLoading, title, longterm, ...rest } = props
  const { onCancel, onSave } = props

  const [errorText, setErrorText] = React.useState<null | string>(null)

  const onSaveHandler = React.useCallback(async () => {
    const values = form.getFieldsValue()

    let hasFormErrors = false
    let hasSlotsErrors = false

    await form.validateFields().catch(err => {
      hasFormErrors = true
    })

    const iSlotsEmpty = isCheckSlotsEmpty(values)
    if (iSlotsEmpty) {
      setErrorText('Заполните хотя бы один слот в днях недели')
      hasSlotsErrors = true
    } else {
      setErrorText(null)
      hasSlotsErrors = false
    }

    !hasFormErrors && !hasSlotsErrors && onSave()
  }, [form, onSave])

  const initialValues = useMemo(() => genScheduleFormInitialValues(), [])

  return (
    <ScheduleModal
      title={title}
      width={850}
      onCancel={onCancel}
      footer={
        <Flex vertical gap="small">
          {errorText && <Alert showIcon message={errorText} type="error" style={{ alignSelf: 'baseline' }} />}
          <Flex justify="center" gap={16}>
            <Button loading={isLoading} onClick={onCancel}>
              Отменить
            </Button>
            <Button loading={isLoading} type="primary" onClick={onSaveHandler}>
              Обновить {longterm ? 'длинную запись' : 'расписание'}
            </Button>
          </Flex>
        </Flex>
      }
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        disabled={isLoading}
        initialValues={initialValues}
        autoComplete="off"
        layout="vertical"
      >
        <ScheduleSlots form={form} isLoading={isLoading} {...rest} />
      </Form>
    </ScheduleModal>
  )
}
