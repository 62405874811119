import { FranchisesForm } from '../../../components/franchises/franchises-form/franchises-form.component'
import { useFranchisesCreatePageForm } from './franchises-create-page-form.hook'

export const FranchisesCreatePageForm = () => {
  const { form, customersOptions, studiosOptions, isLoading, isCreating, onFinishHandler, terminals, tills } =
    useFranchisesCreatePageForm()

  return (
    <FranchisesForm
      form={form}
      isLoading={isLoading}
      isUpdating={isCreating}
      studiosOptions={studiosOptions}
      tills={tills}
      terminals={terminals}
      customersOptions={customersOptions}
      onFinish={onFinishHandler}
    />
  )
}
