export enum AppModal {
  // region ---- schedule page
  SCHEDULE_PAGE_MODAL_CREATE = 'SCHEDULE_PAGE_MODAL_CREATE',
  SCHEDULE_PAGE_MODAL_CONFIRM = 'SCHEDULE_PAGE_MODAL_CONFIRM',
  SCHEDULE_PAGE_MODAL_ERROR = 'SCHEDULE_PAGE_MODAL_ERROR',
  // endregion

  // region ---- schedule management page
  SCHEDULE_MANAGEMENT_PAGE_MODAL_CREATE = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_CREATE',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_EDIT = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_EDIT',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_ERROR = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_ERROR',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_ADD = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_ADD',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFLICTS = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFLICTS',
  // endregion

  // region ---- schedule longterm page
  SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM = 'SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM',
  SCHEDULE_LONGTERM_PAGE_MODAL_EDIT = 'SCHEDULE_LONGTERM_PAGE_MODAL_EDIT',
  SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS = 'SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS',
  SCHEDULE_LONGTERM_PAGE_MODAL_CONFLICTS = 'SCHEDULE_LONGTERM_PAGE_MODAL_CONFLICTS',
  SCHEDULE_LONGTERM_PAGE_MODAL_ADD = 'SCHEDULE_LONGTERM_PAGE_MODAL_ADD',
  SCHEDULE_LONGTERM_PAGE_MODAL_CREATE = 'SCHEDULE_LONGTERM_PAGE_MODAL_CREATE',
  // endregion

  // region ---- schedule group page
  SCHEDULE_GROUP_PAGE_MODAL_BOOKING = 'SCHEDULE_GROUP_PAGE_MODAL_BOOKING',
  SCHEDULE_GROUP_PAGE_MODAL_WAITING = 'SCHEDULE_GROUP_PAGE_MODAL_WAITING',
  SCHEDULE_GROUP_PAGE_MODAL_BOOK_FROM_WAITING = 'SCHEDULE_GROUP_PAGE_MODAL_BOOK_FROM_WAITING',
  SCHEDULE_GROUP_PAGE_MODAL_COMMENTS = 'SCHEDULE_GROUP_PAGE_MODAL_COMMENTS',
  SCHEDULE_GROUP_PAGE_MODAL_QR_READER = 'SCHEDULE_GROUP_PAGE_MODAL_QR_READER',
  // endregion

  // region ---- contracts info page
  CONTRACTS_INFO_PAGE_CLIENT_MODAL_CREATE = 'CONTRACTS_INFO_PAGE_CLIENT_MODAL_CREATE',
  CONTRACTS_INFO_PAGE_CONTRACT_MODAL_CREATE = 'CONTRACTS_INFO_PAGE_CONTRACT_MODAL_CREATE',
  CONTRACTS_INFO_PAGE_PAID_MODAL = 'CONTRACTS_INFO_PAGE_PAID_MODAL',
  CONTRACTS_INFO_PAGE_CONFIRM_MODAL = 'CONTRACTS_INFO_PAGE_CONFIRM_MODAL',
  CONTRACTS_INFO_PAGE_DELETE_MODAL = 'CONTRACTS_INFO_PAGE_DELETE_MODAL',
  // endregion

  // region ---- contracts page
  CONTRACTS_PAGE_COUNTERPARTY_MODAL_CREATE = 'CONTRACTS_PAGE_COUNTERPARTY_MODAL_CREATE',
  // endregion

  // region ---- employees schedule
  EMPLOYEES_SCHEDULE_EDIT_MODAL_CONFIRM = 'EMPLOYEES_SCHEDULE_EDIT_MODAL_CONFIRM',
  // endregion

  // region ---- transactions page
  TRANSACTIONS_PAGE_RECEIPTS_MODAL = 'TRANSACTIONS_PAGE_RECEIPTS_MODAL',
  TRANSACTIONS_PAGE_REFUND_MODAL = 'TRANSACTIONS_PAGE_REFUND_MODAL',
  // endregion

  // region ---- transactions create page
  TRANSACTIONS_CREATE_PAGE_MODAL_PRODUCTS = 'TRANSACTIONS_CREATE_PAGE_MODAL_PRODUCTS',
  TRANSACTIONS_CREATE_PAGE_MODAL_CONFIRM = 'TRANSACTIONS_CREATE_PAGE_MODAL_CONFIRM',
  TRANSACTIONS_CREATE_PAGE_MODAL_WAITING = 'TRANSACTIONS_CREATE_PAGE_MODAL_WAITING',
  // endregion

  // region ---- clients edit page
  CLIENTS_EDIT_PAGE_BOOKINGS_ACTIVE_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_BOOKINGS_ACTIVE_RECEIPTS_MODAL',
  CLIENTS_EDIT_PAGE_BOOKINGS_HISTORY_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_BOOKINGS_HISTORY_RECEIPTS_MODAL',
  CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_PAUSE_MODAL = 'CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_PAUSE_MODAL',
  CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_RECEIPTS_MODAL',
  CLIENTS_EDIT_PAGE_PURCHASES_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_PURCHASES_RECEIPTS_MODAL',
  CLIENTS_EDIT_PAGE_CONFIRM_MODAL = 'CLIENTS_EDIT_PAGE_CONFIRM_MODAL',
  // endregion

  // region ---- positions edit page
  POSITIONS_EDIT_PAGE_MODAL_CONFIRM = 'POSITIONS_EDIT_PAGE_MODAL_CONFIRM',
  // endregion

  // region ---- clients edit page
  PROMOTIONS_PAGE_SALES_MODAL_CREATE = 'PROMOTIONS_PAGE_SALES_MODAL_CREATE',
  PROMOTIONS_PAGE_CODES_MODAL_CREATE = 'PROMOTIONS_PAGE_CODES_MODAL_CREATE',
  // endregion

  CUSTOM_FIELDS_PAGE_MODAL_EDIT = 'CUSTOM_FIELDS_PAGE_MODAL_EDIT',
  CUSTOM_FIELDS_PAGE_MODAL_CREATE = 'CUSTOM_FIELDS_PAGE_MODAL_CREATE',

  FRANCHISES_PAGE_TERMINAL_FORM = 'FRANCHISES_PAGE_TERMINAL_FORM',

  FRANCHISES_PAGE_TILL_FORM = 'FRANCHISES_PAGE_TILL_FORM',

  FRANCHISES_EDIT_PAGE_MODAL_CONFIRM = 'FRANCHISES_EDIT_PAGE_MODAL_CONFIRM',
}

export type AppModalQueueItem<Props extends AppModalBaseProps = AppModalBaseProps> = {
  modal: AppModal
  props?: Props
}

export interface AppModalBaseProps {
  onCancel?: () => void
}
