import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { genScheduleEditExercisesFormValues } from '../../../../mapping/exercises-timetable.mapping'
import {
  genScheduleManagementPagePeekAboutStudios,
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutExercisesFilterOptions,
} from '../schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { isDefAndNotEmpty } from '../../../../types/lang.types'

export const genScheduleManagementPageModalEditIsLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalEdit.isLoading

export const getScheduleManagementPageModalEditFormValues = createSelector(
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutStudios,
  getCurrentStudioOffset,
  (exercisesFilter, studios, offset) => genScheduleEditExercisesFormValues(exercisesFilter, studios?.content, offset)
)

export const getScheduleManagementPageModalEditClients = createSelector(
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutExercisesFilterOptions,
  (exercisesFilter, exercisesFilterOptions) => {
    if (!isDefAndNotEmpty(exercisesFilter?.clients) || !isDefAndNotEmpty(exercisesFilterOptions?.clients)) {
      return []
    }
    if (exercisesFilter.clients[0] === 'all') {
      return exercisesFilterOptions?.clients.map(({ id, phone }) => ({ id, phone }))
    }
    if (exercisesFilter.clients[0] !== 'all') {
      return exercisesFilter.clients
        .map(clientId => exercisesFilterOptions?.clients.find(client => client.id === clientId))
        .filter((client): client is ExercisesTimetableApi.Client => client !== undefined)
        .map(({ id, phone }) => ({ id, phone }))
    }
  }
)
