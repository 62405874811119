import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import weekday from 'dayjs/plugin/weekday'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import { YMInitializer } from 'react-yandex-metrika'
import { apiV1_1 } from '@api/api-v1.1'
import { apiV2 } from '@api/api-v2'

import { api } from '../api/api'
import { store } from '../store/app.store'
import { history } from '../utils/history.utils'
import { AppRoot } from './app-root.component'
import './app-root.styles.less'
import { AppUI } from './app-ui.component'
import { AUTH_ID } from './app.config'

const keycloakClient = api.getKeycloakInstance()
const setTokens = (tokens: AuthClientTokens) => {
  api.setTokens(tokens)
  apiV1_1.setTokens(tokens)
  apiV2.setTokens(tokens)
}

const keycloakConfig = {
  onLoad: 'login-required',
  checkLoginIframe: AUTH_ID === 'React-auth-localhost' ? false : true,
}

// Setup dayjs
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(weekday)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

export const App: React.FC = () => {
  return (
    <ReactKeycloakProvider authClient={keycloakClient} initOptions={keycloakConfig} onTokens={setTokens}>
      <ReduxProvider store={store}>
        <ConnectedRouter history={history}>
          <AppUI>
            <AppRoot />
            <YMInitializer
              accounts={[97196721]}
              options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
            />
          </AppUI>
        </ConnectedRouter>
      </ReduxProvider>
    </ReactKeycloakProvider>
  )
}
