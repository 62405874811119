import { NNumber } from '../../types/lang.types'
import { DEFAULT_PAGE_SIZE } from '../../utils/pagination.utils'

export interface PromotionsPageUrlParams {
  section?: PromotionsPageSection
}

export interface PromotionsPageQueryParams {
  page?: NNumber
  size?: NNumber
}

export interface PromotionsPageParams {
  section: PromotionsPageSection
  page?: NNumber
  size?: NNumber
}

export enum PromotionsPageSection {
  SALES = 'sales',
  CODES = 'codes',
}

export const PROMOTIONS_PAGE_SIZE: number = DEFAULT_PAGE_SIZE
