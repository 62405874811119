import { Flex, Row, Col, Form, Select, Input, Button, FormInstance } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import { formatPluralized } from '../../../../format/text.format'
import { isDef } from '../../../../types/lang.types'
import { genDaysOptions } from '../../../../utils/days.utils'
import { ScheduleEditExercisesFormValues } from '../schedule-modal-edit.types'

interface Props {
  form: FormInstance<ScheduleEditExercisesFormValues>
  studioId: string
  isLoading: boolean
  initialValues: ScheduleEditExercisesFormValues
  totalExercises?: number
  studiosRoomsOptions?: DefaultOptionType[]
  trainersOptions?: DefaultOptionType[]
  onEditExercises: () => void
}

export const ScheduleModalEditExercisesUpdating: React.FC<Props> = props => {
  const { form, studioId, isLoading, totalExercises, studiosRoomsOptions, trainersOptions, initialValues } = props
  const { onEditExercises } = props

  const selectedTrainers = Form.useWatch(['trainers', 'newValue'], form) || []

  const isTrainerOptionDisabled = (optionValue: string) => {
    return selectedTrainers.includes('noTrainer') && optionValue !== 'noTrainer'
  }

  const onChangeStudioHandler = (value: string): void => {
    const values = form.getFieldsValue()

    form.setFieldsValue({
      ...values,
      studio: {
        ...values.studio,
        newValue: value,
      },
      room: {
        ...values.room,
        newValue: undefined,
      },
    })
  }

  const onEditHandler = async () => {
    try {
      await form.validateFields([
        ['room', 'newValue'],
        ['trainers', 'newValue'],
        ['dayOfWeek', 'newValue'],
        ['timeFrom', 'newValue'],
        ['timeTo', 'newValue'],
      ])
      onEditExercises()
    } catch (errorInfo) {}
  }

  const onChangeTrainersHandler = (value: string[]) => {
    if (value.includes('noTrainer')) {
      form.setFieldsValue({ trainers: { newValue: ['noTrainer'] } })
    }
  }

  return (
    <Flex gap={16} vertical align="baseline">
      <div style={{ width: '100%' }}>
        {/* {isDef(initialValues['studio']) && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Локация"
                name={['studio', 'oldValue']}
              >
                <Select placeholder="Выберите" disabled options={studiosOptions} suffixIcon={null} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Выберите новое значение"
                name={['studio', 'newValue']}
              >
                <Select
                  placeholder="Выберите"
                  options={studiosOptions}
                  onChange={onChangeStudioHandler}
                />
              </Form.Item>
            </Col>
          </Row>
        )} */}
        {isDef(initialValues['room']) && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item className="schedule-modal-edit__form-item" label="Пространство" name={['room', 'oldValue']}>
                <Select placeholder="Выберите" disabled options={studiosRoomsOptions} suffixIcon={null} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Выберите новое значение"
                name={['room', 'newValue']}
                rules={[{ required: true, message: 'Выберите значение' }]}
              >
                <Select
                  placeholder="Выберите"
                  options={studiosRoomsOptions?.filter(room => room.studioId === studioId)}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {isDef(initialValues['trainers']) && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                name={['trainers', 'oldValue']}
                label="Исполнитель"
                rules={[{ required: true, message: 'Пожалуйста, выберите исполнителя' }]}
              >
                <Select
                  disabled
                  placeholder="Выберите исполнителя"
                  mode="multiple"
                  options={[{ label: 'Без исполнителя', value: 'noTrainer' }, ...(trainersOptions || [])]}
                  suffixIcon={null}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                name={['trainers', 'newValue']}
                label="Выберите новое значение"
                rules={[{ required: true, message: 'Выберите значение' }]}
              >
                <Select placeholder="Выберите" mode="multiple" onChange={onChangeTrainersHandler}>
                  <Select.Option value="noTrainer" disabled={isTrainerOptionDisabled('noTrainer')}>
                    Без исполнителя
                  </Select.Option>
                  {trainersOptions?.map(option => (
                    <Select.Option
                      key={option.value}
                      value={option.value}
                      disabled={isTrainerOptionDisabled(String(option.value))}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        {isDef(initialValues['dayOfWeek']) && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Текущий день недели"
                name={['dayOfWeek', 'oldValue']}
              >
                <Select placeholder="Выберите" disabled options={genDaysOptions(true)} suffixIcon={null} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Выберите новое значение"
                required
                name={['dayOfWeek', 'newValue']}
                rules={[{ required: true, message: 'Выберите значение' }]}
              >
                <Select placeholder="Выберите" options={genDaysOptions(true)} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {isDef(initialValues['timeFrom']) && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Текущее время начала"
                name={['timeFrom', 'oldValue']}
              >
                <Input type="time" disabled className="schedule-modal-edit__time-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Выберите новое время начала"
                rules={[{ required: true, message: 'Выберите значение' }]}
                name={['timeFrom', 'newValue']}
              >
                <Input type="time" className="schedule-modal-edit__time-input" />
              </Form.Item>
            </Col>
          </Row>
        )}
        {isDef(initialValues['timeTo']) && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Текущее время окончания"
                name={['timeTo', 'oldValue']}
              >
                <Input type="time" disabled className="schedule-modal-edit__time-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="schedule-modal-edit__form-item"
                label="Выберите новое время окончания"
                rules={[{ required: true, message: 'Выберите значение' }]}
                name={['timeTo', 'newValue']}
              >
                <Input type="time" className="schedule-modal-edit__time-input" />
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
      <Button htmlType="button" type="primary" loading={isLoading} onClick={onEditHandler}>
        Применить изменения для {formatPluralized(totalExercises || 0, ['записи', 'записей', 'записей'])}
      </Button>
    </Flex>
  )
}
