import { all, put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'
import { notification } from 'antd'

import { modalActions } from '../../../common/modal/modal.slice'
import { genPromotionsSalesDTO } from '../../../../mapping/promotions.mapping'
import { isDef } from '../../../../types/lang.types'
import { getRouterState } from '../../../common/router/router.selectors'
import { promotionsPageSalesActions } from './promotions-page-sales.slice'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { api } from '../../../../api/api'
import { callApi } from '../../../../utils/sagas.utils'
import { genPromotionsPageQueryParams } from '../../../../pages/promotions-page/promotions-page.utils'

export function* fetchAllSales(action: ReturnType<typeof promotionsPageSalesActions.fetchAllSales>) {
  try {
    const { size, page, isShowPast = true } = action.payload

    const paginationParams = genPaginationParamsDTO(page, size)
    const params = {
      ...paginationParams,
      showPast: isShowPast,
    }

    const response: Awaited<ReturnType<typeof api.promotionsService.fetchAllPromotionsSales>> = yield callApi(
      api.promotionsService.fetchAllPromotionsSales,
      params
    )

    yield put(promotionsPageSalesActions.fetchAllSalesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesActions.fetchAllSalesError(new Error()))
  }
}

export function* removeSales(action: ReturnType<typeof promotionsPageSalesActions.removeSales>) {
  try {
    const { id } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    yield callApi(api.promotionsService.removePromotionsSales, id)

    yield put(promotionsPageSalesActions.removeSalesSuccess())
    yield put(promotionsPageSalesActions.fetchAllSales({ ...params }))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesActions.removeSalesError(new Error()))
  }
}

export function* createSales(action: ReturnType<typeof promotionsPageSalesActions.createSales>) {
  try {
    const promotionsSalesDTO = genPromotionsSalesDTO(action.payload)

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    if (isDef(promotionsSalesDTO)) {
      yield callApi(api.promotionsService.createPromotionsSales, promotionsSalesDTO)
      yield put(promotionsPageSalesActions.createSalesSuccess())

      yield notification.success({
        message: 'Акция успешно создана',
      })

      yield put(promotionsPageSalesActions.fetchAllSales({ ...params }))

      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesActions.createSalesError(new Error()))
  }
}

export function* fetchPromotionsModalData(
  action: ReturnType<typeof promotionsPageSalesActions.fetchPromotionsModalData>
) {
  try {
    const [counterparties, franchises, categories, studios]: [
      Awaited<ReturnType<typeof api.contractsService.fetchAll>>,
      Awaited<ReturnType<typeof api.organizationsService.fetchAll>>,
      Awaited<ReturnType<typeof api.clientsCategoryService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>
    ] = yield all([
      callApi(api.contractsService.fetchAll, { size: 300 }),
      callApi(api.organizationsService.fetchAll, { size: 300 }),
      callApi(api.clientsCategoryService.fetchAll, { size: 300 }),
      callApi(api.studiosService.fetchAll, { size: 300 }),
    ])

    yield put(
      promotionsPageSalesActions.fetchPromotionsModalDataSuccess({
        counterparties: counterparties.data,
        franchises: franchises.data,
        categories: categories.data,
        studios: studios.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesActions.fetchPromotionsModalDataError(new Error()))
  }
}

export function* searchPromotionsProducts(
  action: ReturnType<typeof promotionsPageSalesActions.searchPromotionsProducts>
) {
  try {
    const { name, type } = action.payload

    const response: Awaited<ReturnType<typeof api.promotionsService.searchPromotionsProduct>> = yield callApi(
      api.promotionsService.searchPromotionsProduct,
      { name, type }
    )
    yield put(promotionsPageSalesActions.searchPromotionsProductsSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesActions.searchPromotionsProducts(new Error()))
  }
}

export function* promotionsPageSalesSagas() {
  yield takeLatest(promotionsPageSalesActions.fetchAllSales, fetchAllSales)
  yield takeLatest(promotionsPageSalesActions.fetchPromotionsModalData, fetchPromotionsModalData)
  yield takeLatest(promotionsPageSalesActions.removeSales, removeSales)
  yield takeLatest(promotionsPageSalesActions.createSales, createSales)
  yield takeLatest(promotionsPageSalesActions.searchPromotionsProducts, searchPromotionsProducts)
}
