import { Form } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getTerminalsWithOptions } from '../../../store/common/entity/terminal/terminal.selectors'
import { FranchisesFormValues } from '../../../components/franchises/franchises-form/franchises-form.types'
import { getTillsWithOptions } from '../../../store/common/entity/till/till.selectors'
import {
  getCustomersOptions,
  getFranchiseFormValues,
  getFranchisesIsLoading,
  getFranchisesIsUpdating,
  getStudiosFranchisesOptions,
} from '../../../store/pages/franchises-edit-page/franchises-edit-page.selectors'
import { franchisesEditPageActions } from '../../../store/pages/franchises-edit-page/franchises-edit-page.slice'
import { isDef } from '../../../types/lang.types'
import { useFranchisesEditPageParams } from '../franchises-edit-page-hooks/franchises-edit-page-params.hook'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { genFranchisesPagePath } from '../../../format/path.format'

export function useFranchisesEditPageForm() {
  const [form] = Form.useForm<FranchisesFormValues>()

  const { id } = useFranchisesEditPageParams()

  const history = useHistory()

  const dispatch = useDispatch()
  const terminals = useSelector(getTerminalsWithOptions)
  const tills = useSelector(getTillsWithOptions)

  const franchiseFormValues = useSelector(getFranchiseFormValues)
  const customersOptions = useSelector(getCustomersOptions)

  const isLoading = useSelector(getFranchisesIsLoading)
  const isUpdating = useSelector(getFranchisesIsUpdating)
  const studiosOptions = useSelector(getStudiosFranchisesOptions)

  React.useEffect(() => {
    if (isDef(franchiseFormValues)) {
      form.setFieldsValue(franchiseFormValues)
    }
  }, [form, franchiseFormValues])

  const onEditFranchiseHandler = React.useCallback(
    (values: FranchisesFormValues): void => {
      if (isDef(id)) {
        dispatch(franchisesEditPageActions.updateFranchise({ id, data: values }))
      }
    },
    [dispatch, id]
  )

  const onRemoveFranchiseHandler = (): void => {
    const onSubmit = () => {
      if (isDef(id)) {
        dispatch(
          franchisesEditPageActions.removeFranchise({
            franchiseId: id,
            successRedirect: () => history.push(genFranchisesPagePath()),
          })
        )
      }
    }

    dispatch(
      modalActions.show({
        modal: AppModal.FRANCHISES_EDIT_PAGE_MODAL_CONFIRM,
        props: {
          title: 'Удаление компании',
          text: 'Вы уверены что хотите удалить компанию?',
          onSubmit,
        },
      })
    )
  }

  return {
    form,
    customersOptions,
    terminals,
    tills,
    isLoading,
    isUpdating,
    studiosOptions,
    onEditFranchiseHandler,
    onRemoveFranchiseHandler,
  }
}
