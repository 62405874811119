import React from 'react'
import { Flex } from 'antd'

import { PromotionsHeader } from './promotions-page-header/promotions-page.component'
import { PromotionsTabs } from './promotions-page-tabs/promotions-page-tabs.component'

export const PromotionsPage = () => {
  return (
    <Flex vertical>
      <PromotionsHeader />
      <PromotionsTabs />
    </Flex>
  )
}
