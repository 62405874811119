import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'
import { notification } from 'antd'

import { genPromotionsCodesDTO, genPromotionsCodesPatternDTO } from '../../../../mapping/promotions.mapping'
import { isDef } from '../../../../types/lang.types'
import { genPromotionsPageQueryParams } from '../../../../pages/promotions-page/promotions-page.utils'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { api } from '../../../../api/api'
import { callApi } from '../../../../utils/sagas.utils'
import { promotionsPageCodesActions } from './promotions-page-codes.slice'
import { getRouterState } from '../../../common/router/router.selectors'
import { modalActions } from '../../../common/modal/modal.slice'

export function* fetchAllCodes(action: ReturnType<typeof promotionsPageCodesActions.fetchAllCodes>) {
  try {
    const { size, page, isShowPast = true } = action.payload

    const paginationParams = genPaginationParamsDTO(page, size)
    const params = {
      ...paginationParams,
      showPast: isShowPast,
    }

    const response: Awaited<ReturnType<typeof api.promotionsService.fetchAllPromotionsCodes>> = yield callApi(
      api.promotionsService.fetchAllPromotionsCodes,
      params
    )

    yield put(promotionsPageCodesActions.fetchAllCodesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesActions.fetchAllCodesError(new Error()))
  }
}

export function* removeCodes(action: ReturnType<typeof promotionsPageCodesActions.removeCodes>) {
  try {
    const { id } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    yield callApi(api.promotionsService.removePromotionsCodes, id)

    yield put(promotionsPageCodesActions.removeCodesSuccess())
    yield put(promotionsPageCodesActions.fetchAllCodes({ ...params }))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesActions.removeCodesError(new Error()))
  }
}

export function* removePatternCodes(action: ReturnType<typeof promotionsPageCodesActions.removePatternCodes>) {
  try {
    const { id } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    yield callApi(api.promotionsService.removePromotionsPatternCodes, id)

    yield put(promotionsPageCodesActions.removePatternCodesSuccess())
    yield put(promotionsPageCodesActions.fetchAllCodes({ ...params }))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesActions.removePatternCodesError(new Error()))
  }
}

export function* createCodes(action: ReturnType<typeof promotionsPageCodesActions.createCodes>) {
  try {
    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    const promotionsCodeDTO = genPromotionsCodesDTO(action.payload)

    if (isDef(promotionsCodeDTO)) {
      yield callApi(api.promotionsService.createPromotionsCodes, promotionsCodeDTO)
      yield put(promotionsPageCodesActions.createCodesSuccess())

      yield notification.success({
        message: 'Промокод успешно создан',
      })

      yield put(promotionsPageCodesActions.fetchAllCodes({ ...params }))
      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesActions.createCodesError(new Error()))
  }
}

export function* createCodesPattern(action: ReturnType<typeof promotionsPageCodesActions.createCodesPattern>) {
  try {
    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    const promotionsCodePatternDTO = genPromotionsCodesPatternDTO(action.payload)

    if (isDef(promotionsCodePatternDTO)) {
      yield callApi(api.promotionsService.createPromotionsCodesPattern, promotionsCodePatternDTO)

      yield put(promotionsPageCodesActions.createCodesPatternSuccess())

      yield notification.success({
        message: 'Промокод успешно создан',
      })

      yield put(promotionsPageCodesActions.fetchAllCodes({ ...params }))
      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesActions.createCodesPatternError(new Error()))
  }
}

export function* promotionsPageCodesSagas() {
  yield takeLatest(promotionsPageCodesActions.fetchAllCodes, fetchAllCodes)
  yield takeLatest(promotionsPageCodesActions.removeCodes, removeCodes)
  yield takeLatest(promotionsPageCodesActions.removePatternCodes, removePatternCodes)
  yield takeLatest(promotionsPageCodesActions.createCodes, createCodes)
  yield takeLatest(promotionsPageCodesActions.createCodesPattern, createCodesPattern)
}
