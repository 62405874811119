import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'

import {
  genScheduleManagementPageModalEditIsLoading,
  getScheduleManagementPageModalEditClients,
  getScheduleManagementPageModalEditFormValues,
} from '../../../store/pages/schedule-management-page/schedule-management-page-modal-edit/schedule-management-page-modal-edit.selectors'
import { scheduleManagementPageModalEditActions } from '../../../store/pages/schedule-management-page/schedule-management-page-modal-edit/schedule-management-page-modal-edit.slice'
import {
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutExercisesTotalElement,
  genScheduleManagementPagePeekAboutStudiosOptions,
  genScheduleManagementPagePeekAboutStudiosRoomsOptions,
  genScheduleManagementPagePeekAboutTrainersOptions,
} from '../../../store/pages/schedule-management-page/schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { ExercisesTimetableApi } from '../../../api/types/exercises-timetable-api.types'

export function useScheduleManagementPageModalEdit(studioId: string, scheduleId: string) {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const studiosOptions = useSelector(genScheduleManagementPagePeekAboutStudiosOptions)
  const studiosRoomsOptions = useSelector(genScheduleManagementPagePeekAboutStudiosRoomsOptions)
  const trainersOptions = useSelector(genScheduleManagementPagePeekAboutTrainersOptions)
  const isLoading = useSelector(genScheduleManagementPageModalEditIsLoading)
  const formValues = useSelector(getScheduleManagementPageModalEditFormValues)
  const clients = useSelector(getScheduleManagementPageModalEditClients)
  const exercisesFilter = useSelector(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises = useSelector(genScheduleManagementPagePeekAboutExercisesTotalElement)

  const onEditExercisesHandler = useCallback((): void => {
    const scheduleFormValues = form.getFieldsValue()
    dispatch(scheduleManagementPageModalEditActions.updateExercises({ studioId, scheduleId, scheduleFormValues }))
  }, [dispatch, form, scheduleId, studioId])

  const onEditExercisesCommentHandler = useCallback((): void => {
    const comment = form.getFieldValue('comment')
    dispatch(scheduleManagementPageModalEditActions.updateExercisesComment({ studioId, scheduleId, comment }))
  }, [dispatch, form, scheduleId, studioId])

  const onRemoveExercisesHandler = useCallback((): void => {
    dispatch(scheduleManagementPageModalEditActions.removeExercises({ studioId, scheduleId }))
  }, [dispatch, scheduleId, studioId])

  const onPayExercisesHandler = useCallback(
    (phone: string, paymentType?: ExercisesTimetableApi.ExercisesTimetablePaymentType): void => {
      if (paymentType) {
        dispatch(scheduleManagementPageModalEditActions.payExercises({ studioId, scheduleId, paymentType, phone }))
      }
    },
    [dispatch, scheduleId, studioId]
  )

  const onCreateBookingsHandler = useCallback((): void => {
    const phone = form.getFieldValue('clientPhone')
    if (phone) {
      dispatch(scheduleManagementPageModalEditActions.createBookings({ studioId, scheduleId, phone }))
    }
  }, [dispatch, form, scheduleId, studioId])

  const onCancelBookingsHandler = useCallback(
    (clientId: string): void => {
      const onSubmit = () => {
        dispatch(scheduleManagementPageModalEditActions.cancelBookings({ studioId, scheduleId, clientId }))
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена бронирований',
            text: 'Вы уверены, что хотите отменить бронирования клиента?',
            onSubmit,
          },
        })
      )
    },
    [dispatch, scheduleId, studioId]
  )

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [dispatch, form, formValues])

  useEffect(() => {
    return () => {
      dispatch(scheduleManagementPageModalEditActions.reset())
    }
  }, [dispatch])

  return {
    form,
    formValues,
    isLoading,
    totalExercises,
    studiosOptions,
    studiosRoomsOptions,
    trainersOptions,
    clients,
    exercisesFilter,
    onEditExercisesHandler,
    onEditExercisesCommentHandler,
    onRemoveExercisesHandler,
    onPayExercisesHandler,
    onCreateBookingsHandler,
    onCancelBookingsHandler,
    onCancelHandler,
  }
}
