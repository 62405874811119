export function genMasterServicesFormInitialValues() {
  return {
    onlinePaymentEnabled: false,
    roomRentEnabled: false,
    hasBookingTimeRestriction: false,
    hasMinimumTimeBeforeCancel: false,
    minimumTimeBeforeCancel: 12,
    timeStepMinutes: 60,
    timeStepIsSubServiceDuration: false,
    reviewEnabled: false,
  }
}
