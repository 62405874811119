import * as React from 'react'

import { ScheduleManagementPageModalEditProps } from './schedule-management-page-modal-edit.types'
import { useScheduleManagementPageModalEdit } from './schedule-management-page-modal-edit.hook'
import { ScheduleModalEdit } from '../../../components/schedule/schedule-modal-edit/schedule-modal-edit.component'

export const ScheduleManagementPageModalEdit: React.FC<ScheduleManagementPageModalEditProps> = props => {
  const { studioId, scheduleId } = props
  const {
    form,
    formValues,
    isLoading,
    totalExercises,
    studiosOptions,
    studiosRoomsOptions,
    trainersOptions,
    clients,
    exercisesFilter,
    onEditExercisesHandler,
    onEditExercisesCommentHandler,
    onRemoveExercisesHandler,
    onPayExercisesHandler,
    onCreateBookingsHandler,
    onCancelBookingsHandler,
    onCancelHandler,
  } = useScheduleManagementPageModalEdit(studioId, scheduleId)

  return (
    <ScheduleModalEdit
      form={form}
      studioId={studioId}
      initialValues={formValues}
      isLoading={isLoading}
      totalExercises={totalExercises}
      studiosOptions={studiosOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      trainersOptions={trainersOptions}
      clients={clients}
      exercisesFilter={exercisesFilter}
      scheduleId={scheduleId}
      onEditExercises={onEditExercisesHandler}
      onEditExercisesComment={onEditExercisesCommentHandler}
      onRemoveExercises={onRemoveExercisesHandler}
      onPayExercises={onPayExercisesHandler}
      onCreateBookings={onCreateBookingsHandler}
      onCancelBookings={onCancelBookingsHandler}
      onCancel={onCancelHandler}
    />
  )
}
