import { TypeTagFormat } from '@components/ui/type-tag-format/ui/type-tag-format.component'
import { Tag, Flex, Typography, Button } from 'antd'
import dayjs from 'dayjs'
import React, { FC, useMemo, useState } from 'react'
import { ClientsBookingsApi } from '@api/types/clients-bookings-api.types'

import './unpaid-record-option.styles.less'

import { IUnpaidRecordOptionProps } from './unpaid-record-option.props'
import { formatPluralize } from '../../../../../format/text.format'

export const UnpaidRecordOption: FC<IUnpaidRecordOptionProps> = ({ record, handleAddUnpaidRecord }) => {
  const [isLongRecord] = useState(record?.bookingIds?.length > 1)

  dayjs.locale('ru')

  const duration = useMemo(() => {
    if (!record?.exerciseDuration) return ''
    const duration = dayjs.duration(record?.exerciseDuration)
    const hours = duration.hours()
    const minutes = duration.minutes()

    let formattedDuration = `${hours} час${hours !== 1 ? 'а' : ''}`
    if (hours === 0) formattedDuration = ''
    if (minutes > 0) formattedDuration += ` ${minutes} минут`
    return `, ${formattedDuration}`
  }, [record])

  const longRecordTitle = useMemo(() => {
    if (!isLongRecord) return ''
    if (!record?.timetable) return ''

    const formatDay = (date: string) => dayjs(date).format('D MMMM')
    const formatWeekdays = (timeslots: ClientsBookingsApi.UnpaidRecordTimeslots) => {
      const weekdays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
      const weekdaysInEnglish = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
      const activeDays = Object.keys(timeslots)
      return weekdays.filter((_, index) => activeDays.includes(weekdaysInEnglish[index])).join(', ')
    }

    return `с ${formatDay(record.timetable.dateFrom)} по ${formatDay(record.timetable.dateTo)}, ${formatWeekdays(
      record.timetable.timeslots
    )}`
  }, [record, isLongRecord])

  return (
    <Flex
      gap="medium"
      justify="space-between"
      className="unpaid-record-option"
      data-response-id={record?.responseId}
      data-component-name="UnpaidRecordOption"
    >
      <Flex vertical>
        <Flex>
          {isLongRecord && <Tag color="blue">Длинная запись</Tag>}
          <TypeTagFormat typeFormat={record.exerciseTypeFormat} />
        </Flex>
        <Typography.Text className="unpaid-record-option__title" data-date={record.exerciseDate}>
          {isLongRecord
            ? longRecordTitle
            : `${dayjs(record.exerciseDate, "'YYYY-MM-DDTHH:mm'").format('D MMMM, в HH:mm')}${duration}`}
        </Typography.Text>
        {isLongRecord ? (
          <Typography.Text className="unpaid-record-option__text">
            {record?.exerciseDirection?.name}, {record?.room?.name}
          </Typography.Text>
        ) : (
          <Typography.Text className="unpaid-record-option__text">
            {record?.exerciseDirection?.name}, {record?.exerciseType?.name},{' '}
            {record?.trainers?.[0]?.name ?? 'Тренер отсутствует'}
          </Typography.Text>
        )}
      </Flex>

      <Flex vertical gap="small" align="center" justify="center">
        {isLongRecord && (
          <Typography.Text className="unpaid-record-option__record-count">
            {record?.bookingIds?.length ?? 0}{' '}
            {formatPluralize(record?.bookingIds?.length ?? 0, ['запись', 'записи', 'записей'])}
          </Typography.Text>
        )}
        <Button onClick={() => handleAddUnpaidRecord(record.responseId)}>Добавить</Button>
      </Flex>
    </Flex>
  )
}
