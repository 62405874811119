import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { promotionsPageSalesActions } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.slice'
import {
  getPromotionsSalesCategoryOptions,
  getPromotionsSalesCounterpartiesOptions,
  getPromotionsSalesIsModalLoaded,
  getPromotionsSalesOrganizationOptions,
  getPromotionsSalesStudiosOptions,
  getStudiosList,
} from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.selectors'

export const usePromotionsFormLimitation = () => {
  const dispatch = useDispatch()

  const studiosOptions = useSelector(getPromotionsSalesStudiosOptions)
  const studiosList = useSelector(getStudiosList)
  const franchisesOptions = useSelector(getPromotionsSalesOrganizationOptions)
  const counterpartiesOptions = useSelector(getPromotionsSalesCounterpartiesOptions)
  const clientsCategoryOptions = useSelector(getPromotionsSalesCategoryOptions)
  const isLoadingModal = useSelector(getPromotionsSalesIsModalLoaded)

  const onSelectOrganizationHandler = React.useCallback(
    (selectedStudiosId: string[]) => {
      // @ts-ignore
      const result: { id: string; name: string }[] = selectedStudiosId?.flatMap(selectedStudioId =>
        studiosList
          ?.filter(studio => studio.organization.id === selectedStudioId)
          ?.map(studio => ({ name: studio.name, id: studio.id }))
      )

      dispatch(promotionsPageSalesActions.setSelectedStudios(result))
    },
    [dispatch, studiosList]
  )

  return {
    isLoadingModal,
    studiosOptions,
    franchisesOptions,
    counterpartiesOptions,
    clientsCategoryOptions,
    onSelectOrganizationHandler,
  }
}
