import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Flex, Form, Input, Select } from 'antd'
import { useParams } from 'react-router-dom'
import { Dayjs } from 'dayjs'

import { validateNumericInput } from '../../../../../utils/form/validations-rules-form'
import { ContractsPageInfoParams } from '../../../contracts-info-page.types'
import { useContractsInfoMainTabs } from './contracts-info-tabs-main.hook'
import { snoTypeOptionsObj } from '../../../../contracts-page/contracts-page-modal-create/contracts-modal-create.types'
import { genClientsFormPhoneValidationRules } from '../../../../../components/clients/clients-form/clients-form.utils'
import { InputPhoneNumber } from '../../../../../components/controls/input-phone-number/input-phone-number.component'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { DEFAULT_EMPTY_SYMBOL } from '../../../../../format/text.format'
import style from '../contracts-info-tabs-description.module.scss'
import { genDefaultDateFormat } from '../../../../../format/date.format'

const snoTypeOptions: snoTypeOptionsObj[] = [
  {
    label: 'Общая система налогообложения (ОСНО)',
    value: 'osn',
  },
  {
    label: 'Патентная система налогообложения (ПСН)',
    value: 'patent',
  },
  {
    label: 'Упрощенная система налогообложения "Доходы" (УСН)',
    value: 'usn_income',
  },
  {
    label: 'Упрощенная система налогообложения "Доходы минус расходы" (УСН)',
    value: 'usn_income_outcome',
  },
  {
    label: 'Единый сельскохозяйственный налог (ЕСХН)',
    value: 'esn',
  },
  {
    label: 'Единый налог на вменённый доход (ЕНВД)',
    value: 'envd',
  },
]

interface MainTabsProps {
  onHandleEdit: (id: string, values: ContractsApi.CounterpartiesDTO<Dayjs>) => void
}

export const MainTabs = ({ onHandleEdit }: MainTabsProps) => {
  const { id } = useParams<ContractsPageInfoParams>()
  const { form, counterpartyFormValues, onDeleteContractHandler } = useContractsInfoMainTabs(id)
  const [isModified, setIsModified] = useState(false)
  const [initialFormState, setInitialFormState] = useState<ContractsApi.CounterpartiesDTO>()

  useEffect(() => {
    setInitialFormState(form.getFieldsValue())
  }, [counterpartyFormValues, form])

  function handleOnChange() {
    const currentValue = form.getFieldsValue()
    setIsModified(JSON.stringify(initialFormState) !== JSON.stringify(currentValue))
  }

  function handleEditCounterparty(values: ContractsApi.CounterpartiesDTO<Dayjs>) {
    if (isModified) {
      onHandleEdit(id, values)
      setInitialFormState(form.getFieldsValue())
      setIsModified(false)
    }
  }

  const onChangePhoneHandler = (value: string) => {
    form.setFieldValue('phoneNumber', value)
  }

  return (
    <Form
      autoComplete="off"
      form={form}
      style={{ marginTop: '24px' }}
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        modifier: 'public',
      }}
      onFinish={handleEditCounterparty}
      onChange={handleOnChange}
    >
      <div className={style.mainTabsContainer}>
        <Form.Item
          name="directorName"
          label={counterpartyFormValues?.type === 'COMPANY' ? 'Генеральный директор / ответственный' : 'ФИО'}
          rules={[
            {
              required: true,
              message: 'Введите Генерального директора компании!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="inn"
          label="ИНН"
          rules={[
            {
              min: 10,
              message: 'Длина ИНН - 10 или 12 символов (для физ. лиц)!',
            },
            {
              max: 12,
              message: 'Длина ИНН - 10 или 12 символов (для физ. лиц)!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={12} />
        </Form.Item>
        {counterpartyFormValues?.type === 'INDIVIDUAL' ? (
          <>
            <Form.Item style={{ width: '100%' }} name="birthDate" label="Дата рождения">
              <DatePicker format={genDefaultDateFormat()} onChange={handleOnChange} style={{ width: '100%' }} />
            </Form.Item>
            <Flex justify="space-between" gap="small" style={{ width: '100%' }}>
              <Form.Item name="passportSeries" style={{ width: '100%' }} label="Паспорт серия">
                <Input type="textarea" maxLength={4} />
              </Form.Item>
              <Form.Item name="passportNumber" style={{ width: '100%' }} label="Номер">
                <Input type="textarea" maxLength={6} />
              </Form.Item>
            </Flex>
            <Form.Item name="passportIssuer" label="Кем выдан">
              <Input type="textarea" />
            </Form.Item>
            <Form.Item name="passportIssueDate" style={{ width: '100%' }} label="Дата выдачи">
              <DatePicker format={genDefaultDateFormat()} style={{ width: '100%' }} onChange={handleOnChange} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="companyName"
              label="Наименование компании"
              rules={[
                {
                  required: true,
                  message: 'Введите Наименование компании!',
                },
              ]}
            >
              <Input type="textarea" />
            </Form.Item>
            <Form.Item
              name="kpp"
              label="КПП"
              rules={[
                {
                  required: true,
                  message: 'Введите КПП!',
                },
                {
                  min: 9,
                  message: 'Длина КПП - 9 символов!',
                },
                {
                  max: 9,
                  message: 'Длина КПП - 9 символов!',
                },
              ]}
            >
              <Input type="textarea" maxLength={9} onKeyPress={validateNumericInput} />
            </Form.Item>
            <Form.Item
              name="ogrn"
              label="ОГРН"
              rules={[
                {
                  required: true,
                  message: 'Введите ОГРН!',
                },
                {
                  min: 13,
                  message: 'Длина ОГРН - 13 или 15 символов (ИП)!',
                },
                {
                  max: 15,
                  message: 'Длина ОГРН - 13 или 15 символов (ИП)!',
                },
              ]}
            >
              <Input type="textarea" onKeyPress={validateNumericInput} maxLength={15} />
            </Form.Item>
            <Form.Item
              name="snoType"
              label="Система налогообложения"
              rules={[
                {
                  required: true,
                  message: 'Введите Систему налогообложения!',
                },
              ]}
            >
              <Select defaultValue={DEFAULT_EMPTY_SYMBOL} options={snoTypeOptions} />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="address"
          label={counterpartyFormValues?.type === 'COMPANY' ? 'Юридический адрес' : 'Адрес регистрации'}
          rules={[
            {
              required: counterpartyFormValues?.type === 'COMPANY',
              message:
                counterpartyFormValues?.type === 'COMPANY'
                  ? 'Введите Юридический адрес!'
                  : 'Введите Адрес регистрации!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Контактный Email"
          rules={[
            {
              required: true,
              message: 'Введите Контактный Email!',
            },
            {
              type: 'email',
              message: 'Невалидное значение',
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Контактный телефон" rules={genClientsFormPhoneValidationRules()}>
          <InputPhoneNumber
            value={form.getFieldValue('phoneNumber')}
            onKeyPress={validateNumericInput}
            onChange={onChangePhoneHandler}
            placeholder={''}
            onBlur={() => form.validateFields(['phoneNumber'])}
            onFocus={() =>
              form.setFields([
                {
                  name: 'phoneNumber',
                  errors: [],
                },
              ])
            }
          />
        </Form.Item>
      </div>
      <Flex justify={'space-between'} style={{ marginTop: '24px' }}>
        <Button type={'primary'} danger onClick={onDeleteContractHandler}>
          Удалить контрагента
        </Button>
        <Button type={'primary'} htmlType="submit" disabled={!isModified}>
          Сохранить изменения
        </Button>
      </Flex>
    </Form>
  )
}
