import dayjs from 'dayjs'

import { ClientsBookingsApi } from '../../../api/types/clients-bookings-api.types'

export function filterPastRecords(records: ClientsBookingsApi.UnpaidRecord[], offset: number) {
  const now = dayjs().utcOffset(offset)

  return records.filter(exercise => {
    const exerciseDate = dayjs(exercise.exerciseDate).utcOffset(offset)

    return exerciseDate.isAfter(now)
  })
}
