import React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { PageLoader } from '../../page/page-loader/page-loader.component'
import { Nullable } from '../../../types/lang.types'
import { PromotionsSalesTableRow } from './promotions-sales-table.types'
import { genPromotionsSalesTableColumns } from './promotions-sales-table.utils'

interface PromotionsSalesTableProps {
  sales: Nullable<PromotionsSalesTableRow[]>
  isLoading: boolean
  pagination?: TablePaginationConfig
  onChangePage?: (page: number, pageSize: number) => void
  onChangePageSize?: (pageSize: number) => void
  onEdit?: (id: string) => void
  onRemove: (id: string) => void
}

export const PromotionsSalesTable = ({
  sales,
  isLoading,
  pagination,
  onChangePageSize,
  onChangePage,
  onEdit,
  onRemove,
}: PromotionsSalesTableProps) => {
  const columns: ColumnsType<PromotionsSalesTableRow> = React.useMemo(
    () => genPromotionsSalesTableColumns({ onEdit, onRemove }),
    [onEdit, onRemove]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Table loading={isLoading} dataSource={sales || []} rowKey="id" columns={columns} pagination={paginationConfig} />
  )
}
