import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import { SpaceSize } from 'antd/es/space'
import * as React from 'react'

interface PromotionsSalesCellActionProps {
  forbidRemove?: boolean
  space?: SpaceSize | [SpaceSize, SpaceSize]
  onEdit: () => void
  onRemove: () => void
}

export const PromotionsSalesCellAction = ({
  forbidRemove,
  space = 'middle',
  onEdit,
  onRemove,
}: PromotionsSalesCellActionProps) => {
  return (
    <Space size={space}>
      <Tooltip title="Редактировать">
        <Button icon={<EditOutlined />} size="middle" onClick={onEdit} />
      </Tooltip>
      <Tooltip title="Удалить">
        <Button icon={<CloseOutlined />} size="middle" danger onClick={onRemove} disabled={forbidRemove} />
      </Tooltip>
    </Space>
  )
}
