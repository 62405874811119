import { useDispatch } from 'react-redux'
import React from 'react'

import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { promotionsPageSalesActions } from '../../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales.slice'

export const usePromotionsCodesCreate = () => {
  const dispatch = useDispatch()

  const onSearchHandler = React.useCallback(
    (name: string, type: PromotionsApi.PromotionProductType): void => {
      dispatch(
        promotionsPageSalesActions.searchPromotionsProducts({
          name,
          type,
        })
      )
    },
    [dispatch]
  )

  return {
    onSearchHandler,
  }
}
