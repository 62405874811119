import { FilterOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Dropdown, Flex, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import timezone from 'dayjs/plugin/timezone'
import { FC, useMemo } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { CustomSelect } from '../../custom-select/custom-select.component'
import { ScheduleExercisesFiltersProps } from './schedule-exercises-filters.types'
import { useScheduleExercisesFilters } from './schedule-exercises-filters.hook'
import { genScheduleExercisesFiltersMenuItems } from './schedule-exercises-filters.utils'
import { sortOptionsByLocale } from '../../../utils/options.utils'
import './schedule-exercises-filters.styles.less'

dayjs.extend(utc)
dayjs.extend(timezone)

export const ScheduleExercisesFilters: FC<ScheduleExercisesFiltersProps> = props => {
  const {
    isExercisesLoading,
    studioId,
    exercisesFilter,
    totalExercises,
    dateFrom,
    dateTo,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    weekdaysOptions,
    timeFromOptions,
    timeToOptions,
    clientsOptions,
    longterm = false,
    timetableUpdatePermission,
    onSelectFilter,
    onResetFilter,
    onEditExercises,
    onAddExercises,
  } = props

  const {
    theme,
    dateFilter,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
    isDateFilterOpen,
    hasActiveFilters,
    filteredClientsOptions,
    searchQuery,
    setSearchQuery,
    disabledDate,
    handleTrainersChange,
    handleClientsChange,
    handleStudioChange,
    handleDatesChange,
    handleResetFilter,
    toggleDateFilter,
    handleFilterVisibleChange,
  } = useScheduleExercisesFilters({
    dateFrom,
    dateTo,
    exercisesFilter,
    clientsOptions,
    onSelectFilter,
    onResetFilter,
  })

  const items = useMemo(
    () =>
      genScheduleExercisesFiltersMenuItems(
        {
          trainersOptions,
          roomsOptions,
          weekdaysOptions,
          timeFromOptions,
          timeToOptions,
          paymentStatusOptions,
          paymentTypeOptions,
          timeScopeOptions,
          bookingStatusOptions,
          clientsOptions,
          filteredClientsOptions,
        },
        isDateFilterOpen,
        exercisesFilter,
        longterm,
        searchQuery,
        setSearchQuery,
        toggleDateFilter,
        handleTrainersChange,
        handleClientsChange,
        onSelectFilter
      ),
    [
      trainersOptions,
      roomsOptions,
      weekdaysOptions,
      timeFromOptions,
      timeToOptions,
      paymentStatusOptions,
      paymentTypeOptions,
      timeScopeOptions,
      bookingStatusOptions,
      clientsOptions,
      filteredClientsOptions,
      isDateFilterOpen,
      exercisesFilter,
      longterm,
      searchQuery,
      setSearchQuery,
      toggleDateFilter,
      handleTrainersChange,
      handleClientsChange,
      onSelectFilter,
    ]
  )

  return (
    <div
      className={clsx('schedule-exercises-filters', {
        'schedule-exercises-filters--dark': theme === 'dark',
      })}
    >
      <Flex justify="space-between" align="center">
        <Flex gap={16}>
          <Typography.Title level={4} className="schedule-exercises-filters__title">
            Записи
          </Typography.Title>
          <Dropdown
            className="chedule-exercises-filters__button--filters"
            menu={{ items }}
            trigger={['click']}
            onVisibleChange={handleFilterVisibleChange}
          >
            <Button icon={<FilterOutlined />} loading={isExercisesLoading} disabled={isExercisesLoading}>
              Фильтры
            </Button>
          </Dropdown>
        </Flex>
        <Flex gap={16}>
          <Tooltip title={!timetableUpdatePermission ? 'У вас нет разрешения на это действие' : ''}>
            <Button
              icon={<FilterOutlined />}
              loading={isExercisesLoading}
              disabled={isExercisesLoading || !timetableUpdatePermission}
              onClick={onAddExercises}
            >
              Добавить запись
            </Button>
          </Tooltip>
          <Tooltip title={!timetableUpdatePermission ? 'У вас нет разрешения на это действие' : ''}>
            <Button
              type="primary"
              loading={isExercisesLoading}
              onClick={onEditExercises}
              disabled={isExercisesLoading || (totalExercises || 0) === 0 || !timetableUpdatePermission}
            >
              Действия с {totalExercises} {totalExercises === 1 ? 'записью' : 'записями'}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      {(hasActiveFilters || isDateFilterOpen?.dateFrom || isDateFilterOpen?.dateTo) && (
        <Card className="schedule-exercises-filters__card">
          <Flex wrap="wrap" gap="small">
            {isDefAndNotEmpty(exercisesFilter['trainerIds']) && (
              <CustomSelect
                options={[{ label: 'Без исполнителя', value: 'noTrainer' }, ...(trainersOptions ?? [])]}
                mode="multiple"
                placeholder="Исполнители"
                value={exercisesFilter.trainerIds}
                disabled={isExercisesLoading}
                onChange={handleTrainersChange}
              />
            )}
            {/* {isDefAndNotEmpty(studiosOptions) && (
              <CustomSelect
                options={studiosOptions}
                value={exercisesFilter.studioId}
                mode="multiple"
                placeholder="Локация"
                maxCount={1}
                onChange={handlStudioChange}
              />
            )} */}
            {isDefAndNotEmpty(roomsOptions) && isDefAndNotEmpty(exercisesFilter['roomId']) && (
              <CustomSelect
                options={sortOptionsByLocale(roomsOptions)}
                value={exercisesFilter.roomId}
                mode="multiple"
                placeholder="Пространство"
                maxCount={1}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('roomId', value)}
              />
            )}
            {isDefAndNotEmpty(weekdaysOptions) && isDefAndNotEmpty(exercisesFilter['dayOfWeek']) && (
              <CustomSelect
                options={weekdaysOptions}
                placeholder="День недели"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.dayOfWeek}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('dayOfWeek', value)}
              />
            )}
            {isDefAndNotEmpty(timeFromOptions) && isDefAndNotEmpty(exercisesFilter['timeFrom']) && (
              <CustomSelect
                options={timeFromOptions?.filter(
                  option =>
                    !isDefAndNotEmpty(exercisesFilter.dayOfWeek) || option.days.includes(exercisesFilter.dayOfWeek?.[0])
                )}
                placeholder="Время начала"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.timeFrom}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('timeFrom', value)}
              />
            )}
            {isDefAndNotEmpty(timeToOptions) && isDefAndNotEmpty(exercisesFilter['timeTo']) && (
              <CustomSelect
                options={timeToOptions?.filter(
                  option =>
                    !isDefAndNotEmpty(exercisesFilter.dayOfWeek) || option.days.includes(exercisesFilter.dayOfWeek?.[0])
                )}
                placeholder="Время окончания"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.timeTo}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('timeTo', value)}
              />
            )}
            {isDateFilterOpen.dateFrom && (
              <DatePicker
                className={clsx('schedule-exercises-filters__range-picker', {
                  'schedule-exercises-filters__range-picker--has-value': dateFilter?.[0],
                })}
                style={{ '--custom-select-placeholder': `'Дата начала'` } as React.CSSProperties}
                format="DD MMMM YYYY"
                disabled={isExercisesLoading}
                disabledDate={disabledDate}
                value={dateFilter?.[0]}
                onChange={date => handleDatesChange('from', date)}
              />
            )}
            {isDateFilterOpen.dateTo && (
              <DatePicker
                className={clsx('schedule-exercises-filters__range-picker', {
                  'schedule-exercises-filters__range-picker--has-value': dateFilter?.[1],
                })}
                style={{ '--custom-select-placeholder': `'Дата окончания'` } as React.CSSProperties}
                format="DD MMMM YYYY"
                disabled={isExercisesLoading}
                disabledDate={disabledDate}
                value={dateFilter?.[1]}
                onChange={date => handleDatesChange('to', date)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['bookingPaymentStatus']) && (
              <CustomSelect
                options={paymentStatusOptions}
                placeholder="Статус оплаты"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.bookingPaymentStatus}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('bookingPaymentStatus', value)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['paymentType']) && (
              <CustomSelect
                options={paymentTypeOptions}
                placeholder="Метод оплаты"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.paymentType}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('paymentType', value)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['timeScope']) && (
              <CustomSelect
                options={timeScopeOptions}
                placeholder="Статус записи"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.timeScope}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('timeScope', value)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['bookingStatuses']) && (
              <CustomSelect
                options={bookingStatusOptions}
                placeholder="Статус бронирования"
                mode="multiple"
                value={exercisesFilter.bookingStatuses}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('bookingStatuses', value)}
              />
            )}
            {isDefAndNotEmpty(clientsOptions) && isDefAndNotEmpty(exercisesFilter['clients']) && (
              <CustomSelect
                options={[...(clientsOptions.length > 1 ? [{ label: 'Все', value: 'all' }] : []), ...clientsOptions]}
                placeholder="Гости"
                mode="multiple"
                value={exercisesFilter.clients}
                disabled={isExercisesLoading}
                onChange={handleClientsChange}
              />
            )}
            <Button
              type="text"
              className="schedule-exercises-filters__button"
              disabled={isExercisesLoading}
              onClick={handleResetFilter}
            >
              Сбросить
            </Button>
          </Flex>
        </Card>
      )}
    </div>
  )
}
