import { Row, Col, Flex, Form, Select, Button, FormInstance } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import { formatPluralized } from '../../../../format/text.format'
import { isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { ScheduleEditExercisesFormValues } from '../schedule-modal-edit.types'

interface Props {
  isLoading: boolean
  form: FormInstance<ScheduleEditExercisesFormValues>
  paymentTypesOptions?: DefaultOptionType[]
  paymentType: Nullable<DefaultOptionType>
  longtermClientPhone?: string
  onPayExercises: (phone: string) => void
  onChangePaymentType: (value: string) => void
}

export const ScheduleModalEditExercisesPayment: React.FC<Props> = props => {
  const { isLoading, form, paymentType, paymentTypesOptions, longtermClientPhone } = props
  const { onPayExercises, onChangePaymentType } = props

  const onPayHandler = async () => {
    try {
      await form.validateFields(['paymentType'])
      longtermClientPhone && onPayExercises(longtermClientPhone)
    } catch (errorInfo) {}
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Flex vertical gap={8}>
          <Form.Item
            className="schedule-modal-edit__form-item"
            label="Выберите доступный метод оплаты"
            name="paymentType"
            rules={[{ required: true, message: 'Выберите метод оплаты' }]}
          >
            <Select
              placeholder={isDefAndNotEmpty(paymentTypesOptions) ? 'Выберите' : 'Нет доступных методов оплат'}
              disabled={!isDefAndNotEmpty(paymentTypesOptions)}
              options={paymentTypesOptions}
              onChange={onChangePaymentType}
            />
          </Form.Item>
          {paymentType && paymentType?.affordableExercisesCount !== 0 && (
            <Button htmlType="button" type="primary" loading={isLoading} onClick={onPayHandler}>
              Применить изменения для{' '}
              {formatPluralized(paymentType?.affordableExercisesCount || 0, ['записи', 'записей', 'записей'])}
            </Button>
          )}
        </Flex>
      </Col>
    </Row>
  )
}
