import { createSelector } from '@reduxjs/toolkit'

import { mapCodesToPromotionsCodesTable } from '../../../../mapping/promotions.mapping'
import { AppState } from '../../../app.store'

export const getPromotionsCodesInternal = (state: AppState) => state.promotionsPage.promotionsPageCodes.codes

export const getPromotionsCodesIsLoading = (state: AppState) => state.promotionsPage.promotionsPageCodes.isLoading
export const getPromotionsCodesIsLoaded = (state: AppState) => state.promotionsPage.promotionsPageCodes.isLoaded

export const getPromotionsCodeTotalElements = createSelector(getPromotionsCodesInternal, codes => codes?.totalElements)

export const getPromotionsCodesForTableRow = createSelector(getPromotionsCodesInternal, codes =>
  mapCodesToPromotionsCodesTable(codes?.content)
)
