import { put, select, take, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { isDefAndNotEmpty } from '../../../../types/lang.types'
import {
  genScheduleExercisesEditCommentDTO,
  genScheduleExercisesEditDTO,
  genScheduleExercisesPayDTO,
} from '../../../../mapping/exercises-timetable.mapping'
import { modalActions } from '../../../common/modal/modal.slice'
import { scheduleManagementPageModalEditActions } from './schedule-management-page-modal-edit.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import {
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutExercisesTotalElement,
} from '../schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { apiV2 } from '../../../../api/api-v2'
import { AppModal } from '../../../../types/modal.types'
import { ScheduleModalConflictsAction } from '../../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'
import { PaymentType } from '../../../../api/types/api.types'

function* updateExercises(action: ReturnType<typeof scheduleManagementPageModalEditActions.updateExercises>) {
  const { studioId, scheduleId, scheduleFormValues } = action.payload
  const studioOffset: number | undefined = yield select(getCurrentStudioOffset)
  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  const exercisesDTO = genScheduleExercisesEditDTO(scheduleFormValues, exercisesFilter, studioOffset)

  if (Object.keys(exercisesDTO.update).length !== 0) {
    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      yield callApi(apiV2.exercisesTimetableService.updateExercisesByTimetableId, scheduleId, exercisesDTO)
      yield put(scheduleManagementPageModalEditActions.updateExercisesSuccess())
      yield put(modalActions.closeLast())
      yield put(
        modalActions.show({
          modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
          props: {
            studioId,
            scheduleId,
            action: ScheduleModalConflictsAction.EDIT,
            totalExercises,
          },
        })
      )
    } catch (e) {
      console.error(e)
      yield put(scheduleManagementPageModalEditActions.updateExercisesError(new Error()))
    }
  } else {
    yield put(scheduleManagementPageModalEditActions.updateExercisesSuccess())
    yield put(modalActions.closeLast())
  }
}

function* updateExercisesComment(
  action: ReturnType<typeof scheduleManagementPageModalEditActions.updateExercisesComment>
) {
  const { studioId, scheduleId, comment } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  const exercisesDTO = genScheduleExercisesEditCommentDTO(exercisesFilter, comment)

  if (Object.keys(exercisesDTO.update).length !== 0) {
    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      yield callApi(apiV2.exercisesTimetableService.updateExercisesByTimetableId, scheduleId, exercisesDTO)
      yield put(scheduleManagementPageModalEditActions.updateExercisesCommentSuccess())
      yield put(modalActions.closeLast())
      yield put(
        modalActions.show({
          modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
          props: {
            studioId,
            scheduleId,
            action: ScheduleModalConflictsAction.EDIT,
            totalExercises,
          },
        })
      )
    } catch (e) {
      console.error(e)
      yield put(scheduleManagementPageModalEditActions.updateExercisesCommentError(new Error()))
    }
  } else {
    yield put(scheduleManagementPageModalEditActions.updateExercisesCommentSuccess())
    yield put(modalActions.closeLast())
  }
}

function* removeExercises(action: ReturnType<typeof scheduleManagementPageModalEditActions.removeExercises>) {
  const { studioId, scheduleId } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(apiV2.exercisesTimetableService.removeExercisesByTimetableId, scheduleId, exercisesFilter)
    yield put(scheduleManagementPageModalEditActions.removeExercisesSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: ScheduleModalConflictsAction.EDIT,
          totalExercises,
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalEditActions.removeExercisesError(new Error()))
  }
}

function* payExercises(action: ReturnType<typeof scheduleManagementPageModalEditActions.payExercises>) {
  const { studioId, scheduleId, paymentType, phone } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)

  const scheduleExercisesPayDTO = genScheduleExercisesPayDTO(phone, exercisesFilter, paymentType)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    const response: Awaited<ReturnType<typeof apiV2.exercisesTimetableService.payExercisesByTimetableId>> =
      yield callApi(apiV2.exercisesTimetableService.payExercisesByTimetableId, scheduleId, scheduleExercisesPayDTO)

    if (response && isDefAndNotEmpty(response.data.affordableExercises)) {
      yield put(scheduleManagementPageModalEditActions.payExercisesSuccess())
      yield put(modalActions.closeLast())
      yield put(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
          props: {
            studioId,
            scheduleId,
            action: ScheduleModalConflictsAction.PAY_BOOKING,
            totalExercises: response.data.affordableExercises.length,
          },
        })
      )
    } else {
      notification.error({
        message: 'Ошибка оплаты',
      })
      yield put(websocketTimetableActions.disconnect())
      yield put(scheduleManagementPageModalEditActions.payExercisesError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalEditActions.payExercisesError(new Error()))
  }
}

function* createBookings(action: ReturnType<typeof scheduleManagementPageModalEditActions.createBookings>) {
  const { studioId, scheduleId, phone } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(apiV2.exercisesTimetableService.createBookingsByTimetableId, scheduleId, exercisesFilter, {
      phone,
      paymentType: PaymentType.ON_PLACE,
    })
    yield put(scheduleManagementPageModalEditActions.createBookingsSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: ScheduleModalConflictsAction.EDIT,
          totalExercises,
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalEditActions.createBookingsError(new Error()))
  }
}

function* cancelBookings(action: ReturnType<typeof scheduleManagementPageModalEditActions.cancelBookings>) {
  const { studioId, scheduleId, clientId } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(
      apiV2.exercisesTimetableService.cancelBookingsByTimetableId,
      scheduleId,
      {
        ...exercisesFilter,
        clients: [clientId],
      },
      { refundMethod: 'SERVICE' }
    )
    yield put(scheduleManagementPageModalEditActions.cancelBookingsSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: ScheduleModalConflictsAction.EDIT,
          totalExercises,
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalEditActions.cancelBookingsError(new Error()))
  }
}

export function* scheduleManagementPageModalEditSagas() {
  yield takeLatest(scheduleManagementPageModalEditActions.updateExercises, updateExercises)
  yield takeLatest(scheduleManagementPageModalEditActions.updateExercisesComment, updateExercisesComment)
  yield takeLatest(scheduleManagementPageModalEditActions.removeExercises, removeExercises)
  yield takeLatest(scheduleManagementPageModalEditActions.payExercises, payExercises)
  yield takeLatest(scheduleManagementPageModalEditActions.cancelBookings, cancelBookings)
  yield takeLatest(scheduleManagementPageModalEditActions.createBookings, createBookings)
}
